// BUTI Corp All right Reserved ©

@import "~styles/variables";

.itemName {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%;
  margin: 0
}

.onSaleSign {
  background: #fc0065;
  border-radius: 100px;
  color: #fff;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 4px 6px;
  width: fit-content;
}

.subText {
  color: #8f95a3;
  margin: 0 0 15px 0;
}

.itemDescription {
  @extend .subText;
  color: $text-color-light;
  font-weight: 400;
  line-height: 140%;
  margin-top: 10px;
  margin-bottom: 0;
}

.itemNote {
  @extend .subText;
  font-size: 0.8rem;
  line-height: 1rem;
}

// ------------- Allergens -------------

.allergensContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.allergen {
  border-radius: 6px;
  box-shadow: $shadow-light;
  margin: 0 20px 15px 0;
  padding: 0.2rem 0.6rem;
  pointer-events: none;
}

.allergenIcon {
  height: 22px;
  width: 22px;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

@media only screen and (max-width: 1000px) {
  .itemName {
    font-size: 22px;
  }
}