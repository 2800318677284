// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$quantity-calculator-height: 40px;

.container {
  backdrop-filter: blur(1.4px);
  background: rgba(255, 255, 255, 0.46);
  bottom: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.quantityCalculator {
  background: #000;
  color: #fff;
  height: $quantity-calculator-height;
  margin-right: 1rem;
}

.quantityNumber {
  font-size: 1.1rem;
  min-width: 20px;
  max-width: 20px;
}

.minusButton,
.plusButton {
  & > svg {
    fill: #fff;
    height: 1rem;
    width: 1rem;
  }
}

.confirmButton {
  background: $skipli-red;
  height: $quantity-calculator-height;
  margin: auto 0 auto 1.4rem;
  padding: 0;
  width: $quantity-calculator-height;
  & > svg {
    fill: #fff;
    height: 1rem;
    width: 1rem;
  }
}

.cancelButton {
  @extend .confirmButton;
  background: none;
  border: 2px solid #000;
  & > svg {
    fill: #000;
  }
}
