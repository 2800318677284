// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.header {
  background: linear-gradient(
      188.55deg,
      $primary-light,
      rgba(221, 241, 255, 0) 50%
    ),
    #fff;
  padding: 2.4rem 0 0 0;
}

.headerText {
  color: $text-color;
  max-width: 80rem;
  margin: auto;
}

.heading {
  font-size: 2.6rem;
  margin: 0 0 2.4rem 0;
}

.tabs {
  align-items: flex-end;
  background: transparent;
  border-radius: $global-border-radius $global-border-radius 0 0;
  border-bottom: 1px solid #e0e5fd;
  justify-content: flex-start;
  height: auto;
  padding: 0;
}

.tab {
  background: #fff;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid #fff;
  height: 100%;
  margin-right: 1.4rem;
  max-width: 18rem;
  padding-bottom: 1rem;
  text-align: left;
  &:last-child {
    margin-right: 0;
  }
}

.tabLabel {
  color: $text-color-light;
  & > h2 {
    font-size: 22px;
    margin: 0;
  }
  & > p {
    margin: 0.4rem 0 0 0;
  }
}

.selectedTab {
  box-shadow: none !important;
  border-bottom-color: $primary;
  font-weight: 500 !important;
  & > .tabLabel > h2 {
    color: $primary !important;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    padding-top: 2rem;
  }

  .headerText {
    padding: 0 20px;
  }

  .heading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .tabLabel {
    & > h2 {
      font-size: 18px;
    }
    & > p {
      font-size: 0.7rem;
    }
  }
}
