// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.subNavContainer {
  display: flex;
  align-items: center;
  height: 50px;
}

.subNavItem {
  color: #000;
  height: 100%;
  margin-right: 20px;
  text-transform: capitalize;

  &.selected {
    border-bottom: 2px solid $primary;
    border-radius: 0;
    font-weight: bold;
  }
}
