// BUTI DINERS, INC. All right Reserved ©

.modalBox {
  height: 360px;
  width: 640px;
}

@media only screen and (max-width: 700px) {
  .modalBox {
    width: 100vw;
  }

  .videoPlayer {
    width: 100vw !important;
  }
}
