// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  // background: #fafafb;
  display: flex;
  padding: 50px 0;
}

.image_column,
.text_column {
  width: 50%;
}

.hero_image {
  width: 90%;
  border-radius: 5%;

}

.text_content {
  padding: 0 6rem;
}

.heading {
  font-size: 3rem;
  line-height: 4.8rem;
  margin: 0;
  & > span {
    background: linear-gradient(90deg, #FF5846 0%, $skipli-red 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.paragraph {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 30px 0;
}
.description {
  color: #7a8297;
  font-size: 1.1rem;
  line-height: 1.8rem;

  & > span {
    text-decoration: underline;
    text-underline-position: under;
  }

  strong {
    color: #000; 
  }
}


// ----------------------------------------------
// Bullet Points

.point {
  margin-bottom: 1rem;
  // Display
  display: flex;
  align-items: flex-start;
}

.circleCheck {
  background: $skipli-red;
  border-radius: 100%;
  margin-right: 15px;
  height: 1.6rem;
  width: 1.6rem;
  min-width: 1.6rem;
  // Display
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: #fff;
    height: 0.8rem;
    width: 0.8rem;
  }
}

.pointText {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  margin: -5px 0 0 0;
}

// ----------------------------------------------
// Buttons

.buttons {
  display: flex;
  & > .learnMoreBtn {
    color: $skipli-red !important;
  }
}

.button {
  font-size: 1.2rem !important;
  margin-top: 20px !important;
  margin-left: 0 !important;
  padding: 16px 30px !important;
}

.learnMoreBtn {
  @extend .button;
  box-shadow: none !important;
  color: $skipli-red !important;
  & > svg {
    fill: $skipli-red;
  }
}

.startNowBtn {
  @extend .button;
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  margin-right: 2rem !important;
}

.arrowIcon {
  fill: #fff;
  height: 1rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 1rem;
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column;
  }

  .image_column,
  .text_column {
    width: inherit;
  }

  .image_column {
    margin-top: 40px;
  }

  .hero_image {
    width: 100%;
  }

  .text_content {
    padding: 0 2rem;
  }

  .heading {
    font-size: 3.4rem;
    line-height: 3.6rem;
  }

  .paragraph {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .buttons {
    flex-direction: column;
  }

  .button {
    margin-bottom: 14px !important;
    width: 100%;
  }

  .learnMoreBtn {
    margin-top: 20px !important;
  }
}
