// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "../style.module.scss";

.headerContainer {
  background: #fff;
  border-bottom: 1px solid $border-color-dark;
  padding: 12px $paddingLeftRight;
  // Position
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 50;
}

.header_content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.button {
  background: rgba(0,0,0,.05);
  border-radius: 6px;
  box-shadow: none;
  min-height: 34px;
  height: 34px;
  margin: 0;
  padding: 0;
  min-width: 34px;
  width: 34px;
  &:hover {
    background: #e6f0ff;
    box-shadow: none;
  }
}

.back_button {
  @extend .button;
  margin-right: 20px;
}

.close_button {
  @extend .button;
  margin-left: 20px;
  &.invisible {
    visibility: hidden;
  }
  &.no_margin_left {
    margin-left: 0;
  }
}

.cross_icon {
  fill: #000;
  height: 12px;
  width: 12px;
}

.arrow_icon {
  fill: #000;
  height: 14px;
  width: 14px;
  transform: rotate(180deg);
}

.header {
  font-size: 1.4rem;
  line-height: 140%;
  margin: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .header {
    font-size: 1.2rem;
  }
}
