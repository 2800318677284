// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$item-desktop-container-height: 200px;
$item-mobile-container-height: 138px;
$desktop-item-border-radius: 10px;
$item-shadow: rgba(0, 0, 0, 0.08) 0px 3px 10px 0px,
  rgba(0, 0, 0, 0.01) 0px 1px 0px 0px, rgba(0, 0, 0, 0.01) 0px 0px 5px 0px;
$item-hover-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3),
  0px 1px 0px 0px rgba(0, 0, 0, 0.01), 0px 0px 5px 0px rgba(0, 0, 0, 0.01);

.itemContainer {
  background: #fff;
  border-radius: $desktop-item-border-radius;
  border: 1px solid $border-color-dark;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:hover {
    // box-shadow: $item-hover-shadow;
    border-color: #000;
    & .itemDescription {
      color: $text-color-light;
    }
  }
  &.disabled {
    pointer-events: none;
  }
}

.itemPreview {
  height: $item-desktop-container-height;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemPreviewInfo {
  padding: 16px 16px 0 16px;
}

.textOverFlow {
  display: -webkit-box;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding-right: 0.8rem;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.itemName {
  @extend .textOverFlow;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
  text-transform: capitalize;
  -webkit-line-clamp: 2;
}

.itemDescription {
  @extend .textOverFlow;
  color: #696970;
  font-size: 14px;
  font-weight: 400;;
  line-height: 1.2rem;
  -webkit-line-clamp: 2;
}

.itemThumbnailImg {
  border-radius: 0 $desktop-item-border-radius $desktop-item-border-radius 0;
  height: $item-desktop-container-height;
  width: $item-desktop-container-height;
  min-width: $item-desktop-container-height;
  max-width: $item-desktop-container-height;
  object-fit: cover;
}

.sign {
  border-radius: 100px;
  font-size: 0.8rem;
  padding: 4px 6px;
  text-align: center;
}

.soldOutSign {
  @extend .sign;
  color: #fff;
  background: #2f4858;
  margin-right: 15px;
}

.onSaleSign {
  @extend .sign;
  background: #fc0065;
  color: #fff;
}

.itemPreviewPrice {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 0 16px 16px 16px;
}

.priceBeforeSale {
  margin-right: 10px;
  opacity: 0.6;
}

.itemPrice {
  margin-right: 14px;
}

.itemDetailImg {
  border-radius: 0;
  height: 260px;
  max-height: 260px;
  object-fit: cover;
  width: 100%;
}

.quickAddButton {
  background: $skipli-red;
  border-radius: 100px;
  height: 2rem;
  padding: 0;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 2rem;
  & > svg {
    fill: #fff;
    height: 12px;
    width: 12px;
  }
  &:hover {
    box-shadow: none;
  }
}

@media only screen and (max-width: 600px) {
  .itemContainer {
    border: none;
    border-bottom: 1px solid $border-color-dark;
    border-radius: 0;
    box-shadow: none;
    align-items: center;
    &:hover {
      box-shadow: none;
    }
  }

  .itemPreview {
    height: $item-mobile-container-height;
    justify-content: center;
  }

  .itemPreviewInfo {
    padding: 0;
  }

  .itemName {
    font-weight: 600;
  }

  .itemDescription {
    margin-bottom: 0.6rem;
  }

  .itemPreviewPrice {
    padding: 0;
  }

  .sign {
    border-radius: 100px;
  }

  .itemThumbnailImg {
    border-radius: 6px;
    height: 110px;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }

  .quickAddButton {
    border-radius: 6px;
    height: 1.8rem;
    top: 0.8rem;
    width: 1.8rem;
  }
}
