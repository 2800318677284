// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: $grey;
  border-radius: $global-border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.8rem;
  padding: 0;
}

.tab {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  &.selected {
    background: #fff;
    border: 2px solid $skipli-red;
    color: $skipli-red;
    font-weight: bold;
  }
}
