// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.button {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 14px 24px;
  text-transform: capitalize;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &:hover {
    box-shadow: $button-shadow;
  }
}

.hasShadow {
  box-shadow: $shadow-light;
}

.skipli_red {
  background: $skipli-red;
}

.danger {
  background-color: $danger;
}

.dark {
  background-color: $dark-grey;
}

.info {
  background-color: $info;
}

.primary {
  background-color: $primary;
}

.warning {
  background-color: $warning;
  color: #000;
}

.white {
  background-color: #fff;
  box-shadow: $button-shadow !important;
  color: $primary !important;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}
