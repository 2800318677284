// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.letsChatBox {
  background: $primary;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 50px;
}

.letsChatText {
  & > h2 {
    font-size: 2rem;
    margin: 0;
  }
  & > p {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.secondaryButton {
  margin-top: 20px;
}

@media only screen and (max-width: 700px) {
  .letsChatBox {
    border-radius: $global-border-radius;
    flex-direction: column;
    margin-top: 0;
    padding: 20px 20px;
  }

  .letsChatText {
    & > h2 {
      font-size: 1.8rem;
      margin: 0;
    }
    & > p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .buttons {
    width: 100%;
  }

  .mainButton {
    margin-top: 30px;
  }
}
