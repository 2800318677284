// BUTI Corp All right Reserved ©

.loadingSpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  &.top {
    flex-direction: column;
  }
  &.right {
    flex-direction: row-reverse;
  }
  &.bottom {
    flex-direction: column-reverse;
  }
  &.left {
    flex-direction: row;
  }
}

.message {
  font-size: 1.2rem;
  line-height: 25px;
  text-align: center;
}

@keyframes logoAnimationZoom {
  0% {
    height: 60px;
  }
  25% {
    height: 70px;
  }
  50% {
    height: 60px;
  }
  75% {
    height: 50px;
  }
  100% {
    height: 60px;
  }
}

@keyframes logoAnimationSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes logoAnimationSlide {
  0% {
    justify-content: center;
  }
  25% {
    justify-content: flex-start;
  }
  50% {
    justify-content: center;
  }
  75% {
    justify-content: flex-end;
  }
  100% {
    justify-content: center;
  }
}

.svgContainerWrapper {
  width: 100px;
  display: flex;
  justify-content: center;
}

.svgContainer {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  &.spin {
    animation-duration: 2s;
    animation-name: logoAnimationSpin;
  }
  &.slide {
    animation-duration: 1s;
    animation-name: logoAnimationSlide;
  }
}

.svgIcon {
  height: 60px;
  &.zoom {
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-name: logoAnimationZoom;
  }
}
