// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$border-bottom: 1px solid rgba(144, 161, 189, 0.17);

// -----------------------------------------------------------
// Header
.header {
  background: #fff;
  border-bottom: $border-bottom;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 400;
}

.headerContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 0 1rem;
}

.menuIcon {
  height: 14px;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.cartButton {
  background: $skipli-red;
  border-radius: 8px;
  font-weight: 500;
  margin-right: 0;
  padding: 0px 16px;
  height: 32px;
}

.bagIcon {
  fill: #fff;
  height: 1rem;
  margin-right: 0.8rem;
  width: 1rem;
}

.navbarContainer {
  background: $skipli-red;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  z-index: 50;
  right: 0;
  left: 0;
  & > * {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}

.navbarItemContainer {
  padding: 0.4rem 0 0.2rem 0;
  position: relative;
  width: 20%;
}

.navItemIcon {
  height: 20px;
  &.active {
    & > svg {
      fill: #fff;
    }
  }
  & > svg {
    height: inherit;
    width: 20px;
    fill: #fff;
  }
}

.navItemLabel {
  color: #fff;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  line-height: 1rem;
  &.active {
    font-weight: bold;
  }
}

.itemsCount {
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 3.4rem;
}

@media only screen and (min-width: 700px) {
  .bannerText {
    font-size: 0.9rem;
  }
}
