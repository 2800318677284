// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

// ------------- Modal Styling -------------

.logoName {
  display: flex;
  margin: 1rem 0 1rem 0;
  & > img {
    max-height: 30px;
    max-width: 30px;
    margin-right: 1rem;
  }
  & > h1 {
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.infoContainer {
  display: flex;
  margin-bottom: 1.2rem;
}

.link {
  color: $text-color;
}

.icon {
  height: auto;
  margin-right: 12px;
  width: 16px;
}

.hoursContainer {
  margin-top: -0.6rem;
  padding: 0 2rem;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7rem;
  color: #828282;
  margin: 0 15px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7fafc;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  margin-bottom: 5px;
}

.contactBar {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ------------- Special Hours -------------

.beta_button_container {
  margin-left: 30px;
}

.special_hour_container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  opacity: 0.6;
  &.isToday {
    opacity: 1;
    font-weight: bold;
  }
}

.special_hour_date {
  width: 105px;
}

.time_pickers_group {
  align-items: center;
  display: flex;
}