// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  display: flex;
  padding: 4rem 0;
  &.textOnLeft {
    flex-direction: row-reverse;
  }
}

.image {
  max-height: 500px;
  object-fit: cover;
  width: 50%;
}

.textContainer {
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0 4rem;
  text-align: left;
  width: 50%;
}

.heading {
  font-size: 2.4rem;
  margin-top: 4rem;
}

.description {
  font-weight: 400;
  line-height: 140%;
}

.orderNow {
  font-size: 18px;
  margin: 3rem 0 0 0;
  padding: 8px 30px;
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column !important;
    padding: 2rem 0;
  }

  .image {
    max-height: 100vw;
    width: 100vw;
  }

  .textContainer {
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0 20px;
    width: auto;
  }

  .heading {
    font-size: 1.6rem;
    margin-top: 2rem;
  }

  .orderNow {
    margin-top: 2rem;
    padding: 0.6rem 1rem;
    width: 100%;
  }
}
