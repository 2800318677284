// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.pageContainer {
  padding-top: 70px;
}

.sectionContainer {
  max-width: 1200px;
  margin: 0 auto 100px auto;
  padding: 0 30px;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .sectionContainer {
    margin-bottom: 50px;
  }
}
