// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  display: flex;
  padding: 80px 0;
}

.image_column,
.text_column {
  width: 50%;
}

.hero_image {
  width: 100%;
}

.text_content {
  padding: 0 6rem;
}

.google_logo {
  margin-bottom: 0.4rem;
  width: 14rem;
}

.heading {
  font-size: 3rem;
  line-height: 3.6rem;
  margin: 0;
}

.paragraph {
  color: #000;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  max-width: 36rem;
  margin: 30px 0;
}

// ----------------------------------------------
// Bullet Points

.point {
  margin-bottom: 1rem;
  // Display
  display: flex;
  align-items: flex-start;
}

.circleCheck {
  background: $skipli-red;
  border-radius: 100%;
  margin-right: 15px;
  height: 1.6rem;
  width: 1.6rem;
  min-width: 1.6rem;
  // Display
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: #fff;
    height: 0.8rem;
    width: 0.8rem;
  }
}

.pointText {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  margin: -5px 0 0 0;
}

// ----------------------------------------------
// Buttons

.buttons {
  display: flex;
}

.button {
  font-size: 1.1rem;
  margin-top: 20px;
  margin-left: 0;
  padding: 16px 30px;
}

.learnMoreBtn {
  @extend .button;
  box-shadow: none !important;
  color: $skipli-red !important;
  & > svg {
    fill: $skipli-red;
  }
}

.startNowBtn {
  @extend .button;
  background: $skipli-red;
  margin-right: 1rem;
}

.arrowIcon {
  fill: #fff;
  height: 1rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 1rem;
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column-reverse;
    padding: 0 0 40px 0;
  }

  .image_column,
  .text_column {
    width: inherit;
  }

  .image_column {
    margin-bottom: 2rem;
  }

  .text_content {
    padding: 0 2rem;
  }

  .google_logo {
    width: 10rem;
  }

  .heading {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .paragraph {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 1rem 0 2rem 0;
  }

  .pointText {
    line-height: 1.8rem;
  }

  .button {
    margin-bottom: 2rem;
    padding: 12px 16px;
  }
}
