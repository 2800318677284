// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.device_grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin: 100px 0;
}
.device_description{
  margin-top: 0;
  font-size: 14px;
  width:200px ;
  flex-wrap: wrap;
  font-weight: 300;
  color: #2f4f2f; 
}
.device_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.device_image {
  margin-top: 80px;
  width: 100%; 
  height: auto;
  object-fit: cover; 
  mix-blend-mode: multiply; 

}

.device_name {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #2f4f2f; 
}