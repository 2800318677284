// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0;
}

.image {
  margin-top: -40px;
  width: 100vw;
}

.heroImage {
  position: absolute;
  right: 0;
  top: 30px;
  width: 50vw;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.titleText {
  max-width: 500px;
  text-align: left;
  & > h1 {
    font-size: 3rem;
    line-height: 3.4rem;
    margin: 0;
  }
}

.paragraph {
  color: $text-color-light;
  font-size: 1.4rem;
  line-height: 2rem;
  max-width: 400px;
  margin: 30px 0;
}

.buttons {
  display: flex;
}

.button {
  font-size: 1.1rem;
  margin-top: 20px;
  margin-left: 0;
  padding: 16px 30px;
}

.learnMoreBtn {
  @extend .button;
  color: $skipli-red !important;
  font-weight: 500;
  margin-right: 20px;
}

.seeActionBtn {
  @extend .button;
  background: $skipli-red;
}

.arrowIcon {
  fill: #fff;
  height: 18px;
  margin-left: 10px;
  width: 18px;
}

@media only screen and (max-width: 1000px) {
  .heroImage {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .titleText {
    color: #000;
    & > h1 {
      font-size: 2rem;
    }
  }

  .heroImage {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }

  .paragraph {
    font-size: 0.9rem;
    line-height: 25px;
  }

  .button {
    font-size: 0.8rem;
    margin-top: 0;
    padding: 12px 15px;
  }
}
