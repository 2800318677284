// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  padding: 60px 0;
}

.graphic {
  height: auto;
  margin: 0 0 auto auto;
  width: 100%;
}

.title {
  font-size: 2.8rem;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 60px auto;
  line-height: 3.6rem;
}

.content_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
  margin: auto;
  max-width: 1200px;
}

.testimonial_headline {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin-top: 0;
  & > span {
    color: $skipli-red;
  }
}

.testimonial_description {
  color: #5f7494;
  margin-top: 0;
  font-size: 1.1rem;
  line-height: 2rem;
}

.learn_more_button {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 1.1rem;
  margin-left: 0;
  margin-top: 40px;
}

.arrow_icon {
  fill: #fff;
  height: 0.8rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 0.8rem;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 2rem;
  }

  .title {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 44px;
  }

  .content_grid {
    display: flex;
    flex-direction: column-reverse;
  }

  .testimonial_headline {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .graphic {
    margin-bottom: 40px;
    width: 100%;
  }

  .learn_more_button {
    margin-left: 0;
    width: 100%;
  }
}