// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.customerHeaderContainer {
  border-bottom: 0.5px solid #dadada;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background: #fff;
  // box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.1);
  z-index: 400;
}

.desktopNavbar {
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 40px 24px; */
  display: grid;
  align-items: center;
  padding: 0 30px;
  height: 50px;
  max-width: 1350px;
  margin: auto;
}

.menuIcon {
  cursor: pointer;
  height: 1.2rem;
  width: 1.2rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.desktopNavbarToolbar {
  display: flex;
  align-items: center;
  justify-self: end;
}

.skipliLogoLink {
  justify-self: center;
}

.skipliLogo {
  height: 1.6rem;
  width: fit-content;
}

.toolbarButton {
  background-color: transparent;
  padding: 0;
  & > svg {
    height: 20px;
    width: 20px;
  }
  &:hover {
    box-shadow: none;
  }
}

.cartButton {
  @extend .toolbarButton;
  background: $skipli-red;
  border-radius: 8px;
  padding: 8px 1rem;
  & > svg {
    fill: #fff;
    margin-right: 15px;
  }
}

.rewardsButton {
  @extend .toolbarButton;
  color: $primary;
  & > svg {
    fill: $primary;
    margin-right: 5px;
  }
}
