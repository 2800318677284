// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  max-width: fit-content;
  margin: auto;
  padding: 4rem 1rem;
}

.heading {
  font-size: 2.4rem;
  margin-top: 0;
  text-align: center;
}

.buttons {
  align-items: center;
  display: flex;
  margin-top: 4rem;
}

.orderDeliveryButton,
.orderTakeoutButton {
  border-radius: 10px;
  box-shadow: $shadow-emphasize;
  cursor: pointer;
  padding: 1.8rem 2rem;
  width: 20rem;
}

.orderDeliveryButton {
  margin-right: 3rem;
}

.deliverIcon,
.lunchBagIcon {
  height: 2.4rem;
  width: 2.4rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.buttonText {
  & > h3 {
    font-size: 1.4rem;
    margin: 1rem 0 0 0;
  }
  & > p {
    color: $grey-medium;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin: 0.6rem 0 0 0;
  }
}

.orderArrow {
  background: none;
  display: flex;
  margin: 1.6rem auto 0 0;
  padding: 0;
  text-transform: uppercase;
  & > svg {
    height: 1rem;
    margin-left: 0.6rem;
    width: 1rem;
  }
}

.deliveryPartnersHeading {
  color: $grey-medium;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 3rem 0 1.8rem 0;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 2rem 1rem 4rem 1rem;
  }

  .heading {
    line-height: 3rem;
    max-width: 18rem;
    margin: auto;
  }

  .buttons {
    flex-direction: column;
    margin-top: 2rem;
  }

  .orderDeliveryButton,
  .orderTakeoutButton {
    margin-right: 0;
    width: 74vw;
  }

  .orderDeliveryButton {
    margin-bottom: 2rem;
  }
}
