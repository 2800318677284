// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.noticeBox {
  background: $grey;
  border-radius: 10px;
  color: #000;
  margin-bottom: 2rem;
  padding: 20px;
}

.shop_name,
.shop_address {
  font-weight: bold;
}

.shop_name {
  margin-bottom: 0.4rem;
}

.inputFieldContainer {
  margin-bottom: 15px;
}

.label {
  margin-bottom: 0.4rem;
}

.value {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.6rem;
}

.dateTimePicker {
  display: flex;
  align-items: center;
}

.timeField {
  width: 30px;
}

.colon {
  margin: 0 10px 7px 10px;
  text-align: center;
}

.question {
  margin-top: 0;
}

.pickUpAddress {
  font-weight: bold !important;
}
