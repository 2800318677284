// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "../style.module.scss";

.contentDescriptionContainer {
  background: #f7fafc;
  border-bottom: 1px solid $border-color-dark;
  line-height: 1.4rem;
  padding: 1rem $paddingLeftRight;
}

.title {
  font-size: 1rem;
  margin: 0 0 0.2rem 0;
}

.contentDescription {
  margin: 0;
}
