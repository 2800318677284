// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.backToMenuBtn {
  color: #000;
  background: $border-color-dark;
  border-radius: 100px;
  height: 40px;
  width: 150px;
  font-weight: 500;
  margin-bottom: 60px;
  padding: 0;
  visibility: hidden;
}

.arrowIcon {
  fill: #000;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  transform: rotate(180deg);
}

@media only screen and (max-width: 700px) {
  .backToMenuBtn {
    visibility: visible;
  }
}
