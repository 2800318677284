// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.modalBoxRemoveItem {
  height: 180px;
  width: 300px;
  overflow: hidden !important;
}

.modalContainer {
  padding: 20px;
}

.dialogDescription {
  line-height: 24px;
  margin: 0;
}

.dialogButtonsContainer {
  margin-top: 2rem;
}

.button {
  margin: 0;
  padding: 0.6rem 1.4rem;
  width: 100%;
}

.removeItemButton {
  @extend .button;
  background: transparent;
  box-shadow: none !important;
  border: 2px solid #000;
  color: #000 !important;
}

.keepItemButton {
  @extend .button;
  background: $skipli-red;
  font-weight: bold;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .modal {
    height: 370px !important;
    border-radius: 10px;
    width: 86vw !important;
    min-width: 86vw !important;
  }
}
