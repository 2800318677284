// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
}

.post_title {
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 1.4;
}

.share_post_channels {
  align-items: center;
  background-color: #fafbfd;
  border-top: 0.5px $border-color-dark solid;
  border-bottom: 0.5px $border-color-dark solid;
  display: flex;
  justify-content: flex-end;
  padding: 18px 0;
  position: sticky;
  top: 65px;
}

.channel_button {
  margin-left: 26px;
}

.post_content {
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 1.5rem;
    text-align: justify;
    strong {
      em {
        font-size: 1.2rem;
      }
    }
  }
  ul li {
    text-align: left;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 1rem;
  }
  figure {
    max-width: 100%;
    display: contents;
    img {
      max-width: 100%;
      object-fit: cover;
      height: auto;
    }
    figcaption {
      text-align: left;
      color: $grey-medium;
      font-weight: 400;
      font-size: 14px;
      padding-top: 5px;
    }
  }
  h2 {
    font-size: 1.8rem;
    line-height: 1.4;
  }
  a {
    color: $black-light;
  }
  h3 {
    font-size: 1.3rem;
    margin-top: 2rem;
    font-weight: 800;
  }
  h4 {
    font-size: 1rem;
    margin-top: 4rem;
    font-weight: 800;
  }
  h5 {
    font-size: 0.8rem;
    font-weight: 800;
  }
}

@media screen and (max-width: 1300px) {
  .share_post_channels {
    top: 55px;
  }
}

@media screen and (max-width: 1000px) {
  .post_title {
    font-size: 2rem;
  }
  
  .post_content {
    p {
      line-height: 1.8;
    }
    p,
    ul li {
      font-size: 16px;
      margin-bottom: 1.5rem;
      strong {
        em {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .share_post_channels {
    margin-left: -30px;
    margin-right: -30px;
    padding: 14px 30px;
  }
}