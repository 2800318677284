// BUTI Corp All right Reserved ©

@import "~styles/variables";

.overlay {
  z-index: 2000;
}

.modalBox {
  border-radius: 10px;
  height: 28rem;
  width: 36rem;
}

.headingContainer {
  background: #fff;
  color: #000;
  padding: 1rem 2rem;
  &.error {
    background: $danger;
    color: #fff;
  }
  &.info {
    background: $info;
    color: #fff;
  }
  &.success {
    background: $primary;
    color: #fff;
  }
  &.warning {
    background: $warning;
    color: #fff;
  }
}

.closeModalBtn {
  background: #fff;
  border-radius: 100px;
  box-shadow: $button-shadow;
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0;
  margin-bottom: 0.2rem;
  padding: 0;
  & > svg {
    fill: inherit;
    height: 0.6rem;
    width: 0.6rem;
  }
}

.heading {
  font-size: 1.4rem;
  margin: 0;
}

.description {
  margin-top: 0.4rem;
}

.content {
  padding: 2rem;
}

@media only screen and (max-width: 700px) {
  .modalBox {
    height: 60vh !important;
    width: 90vw;
  }
}
