// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.backButton {
  border: 2px solid #000;
  border-radius: $global-border-radius;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 5px 15px 5px 10px;

  & > svg {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
  }
}
