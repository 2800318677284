// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";


.driver_status_container {
  display: flex;
  align-items: center;
  margin: 30px auto 40px auto;
  width: fit-content;
}

.status_container {
  position: relative;
}

.status {
  display: flex;
}

.status_name {
  font-size: 0.84rem;
  left: -20px;
  width: 84px;
  margin-top: 10px;
  position: absolute;
  text-align: center;
}

.icon {
  background: $border-color-dark;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  & > svg {
    fill: #000;
    height: 18px;
    width: 18px;
  }
  &.highlight {
    background: #000;
    & > svg {
      fill: #fff !important;
    }
  }
}

.line_container {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  background: $border-color-dark;
  height: 4px;
  width: 50px;
  &.highlight {
    background: #000;
  }
}