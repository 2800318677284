// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.pageContent {
  padding-top: 80px;
}

.sloganContainer {
  max-width: 1024px;
  margin: auto;
  padding: 0 30px;
}

.sloganContent {
  display: flex;
  justify-content: space-between;
}

.slogan {
  width: 50%;
  & > h1 {
    font-size: 2.7rem;
    line-height: 60px;
    margin: 80px 0 0 0;
  }
  & > p {
    font-size: 1.2rem;
  }
}

.imgColumn {
  text-align: center;
  width: 50%;
}

.image {
  height: 260px;
}

.mainContent {
  max-width: 1200px;
  margin: auto;
  padding: 60px 30px;
}

.content {
  max-width: 1024px;
  margin: auto;
}

.moduleTitle {
  font-size: 1.4rem;
  margin-bottom: 16px;
}

.shopsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
}

.shop {
  margin: 0 16px 40px 16px;
  width: 30%;
}

@media only screen and (max-width: 1000px) {
  .slogan > h1 {
    font-size: 2.2rem;
    line-height: 50px;
    margin-top: 60px;
  }

  .imgColumn {
    text-align: left;
  }

  .image {
    height: 200px;
  }

  .shop {
    width: 45%;
  }
}

@media only screen and (max-width: 700px) {
  .sloganContainer {
    height: 260px;
  }

  .sloganContent {
    flex-direction: column;
  }

  .slogan {
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    & > h1 {
      font-size: 1.6rem;
      line-height: 40px;
      margin: 0 0 30px 0;
      margin-bottom: 15px;
    }
    & > p {
      font-size: 0.8rem;
    }
  }

  .imgColumn {
    text-align: center;
    width: 100%;
  }

  .image {
    height: 160px;
  }

  .mainContent {
    padding-top: 100px;
  }

  .moduleTitle {
    font-size: 1.2rem;
  }

  .shop {
    margin: 0 0 25px 0;
    width: 100%;
  }
}
