// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  height: 100%;
  text-align: left;
}

.shopContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  border-radius: $global-border-radius;
  box-shadow: $shadow-light;
  height: 100%;
  -webkit-box-shadow: $shadow-light;
  transition: $transition;
  &:hover {
    box-shadow: $button-shadow;
    -webkit-box-shadow: $button-shadow;
  }
}

.thumbnailImg {
  border-radius: $global-border-radius $global-border-radius 0 0;
  height: 190px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.shopInfo {
  padding: 10px 15px 20px 15px;
}

.shopName {
  font-size: 1rem;
  font-weight: bold;
}

.shopAddress {
  color: #000;
  margin: 5px 0 0 0;
  font-size: 0.9rem;
  line-height: 22px;
}

.orderBtn {
  color: $primary;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.9rem;
  justify-content: flex-end;
  & > svg {
    height: 16px;
    margin-left: 8px;
    width: 16px;
    fill: #73bac1;
  }
}

@media only screen and (max-width: 900px) {
  .imgContainer {
    height: 250px;
  }
}
