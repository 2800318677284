// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.quoteContainer {
  background: #000;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 2rem;
  padding: 20px;
}

.quoteError {
  @extend .quoteContainer;
  background: $warning;
}

.quoteSuccess {
  @extend .quoteContainer;
  background: #000;
}

.courierLogo {
  height: 26px;
  margin-bottom: 5px;
}

.skipliLogo {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 15px;
  & > svg {
    margin-right: 12px;
    width: 70px;
  }
}

.quoteEstimate {
  display: flex;
}

.estimate {
  margin-right: 30px;
  & > h2 {
    font-size: 1rem;
    margin: 0 0 4px 0;
  }
  & > p {
    margin: 0;
  }
}

.farLocationMsg {
  display: flex;
  margin: 15px 0 0 0;
  & > svg {
    fill: #fff;
    height: 16px;
    margin-right: 10px;
    width: 16px;
  }
}

.unableToDeliver {
  & > h1 {
    font-size: 1.2rem;
    margin: 0 0 8px 0;
  }
  & > p {
    font-size: 0.9rem;
    margin: 0;
  }
}

.choosePickUpBtn {
  background: #fff;
  border-radius: 100px;
  color: #000;
  margin: 18px 0 0 0;
  padding: 6px 15px;
  & > svg {
    fill: #000;
    height: 18px;
    margin-right: 10px;
    width: 18px;
    transform: rotateY(180deg);
  }
}
