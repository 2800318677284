// BUTI DINERS, INC. All right Reserved ©

$key-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.keyContainer {
  width: 30%;
}

.key {
  width: 100px;
  background: #fff;
  height: 50px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  -webkit-box-shadow: $key-shadow;
  box-shadow: $key-shadow;
  font-weight: bold;
}

.clearIcon {
  height: 16px;
  width: 16px;
}

.backSpaceIcon {
  height: 16px;
  width: 16px;
  transform: rotate(180deg);
}
