// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.adminContentContainer {
  margin-left: 80px;
  position: relative;
  width: 100%;
}

.customerDesktop {
  padding-top: 96px;
}

.customerMobile {
  padding: 0 20px 6rem;
}
