// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.title {
  font-size: 2rem;
  text-align: center;
}

.ratings_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.rating {
  cursor: pointer;
  height: 2.8rem;
  margin: 0 5px;
  width: 2.8rem;
  &.inactive {
    opacity: 0.2;
    transform: scale(.9);
  }
}

.feedback_card {
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin: 30px auto auto auto;
  max-width: 400px;
}

.card_header, .card_body, .card_footer {
  padding: 20px;
}

.card_header, .card_body {
  border-bottom: 1px solid #edf2f9;
}

.card_header {
  font-weight: 600;
}

.feedback_input {
  font-size: .94rem;
  line-height: 1.5;
  width: 100%;
}

.submit_button {
  background: $info;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
}