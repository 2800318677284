// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  border-top: 0.5px solid #dadada;
  // border-bottom: 1px solid $border-color-dark;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  position: sticky;
  background: #fff;
  height: 4rem;
  top: 95px;
  z-index: 100;
}

.content {
  display: flex;
  align-items: center;
  height: inherit;
  max-width: 1240px;
  margin: auto;
  padding: 0 30px;
  overflow: scroll;
}

.category {
  border-bottom: 4px solid transparent;
  border-radius: 0;
  height: 100%;
  margin-right: 2rem;
  opacity: 0.6;
  white-space: nowrap;
  &.selected {
    border-bottom-color: $skipli-red;
    color: $skipli-red;
    opacity: 1;
  }
}
