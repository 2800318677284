// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.moduleContainer {
  margin-bottom: 2rem;
  & > button {
    margin-bottom: 20px;
  }
}

.moduleTitleContainer {
  display: flex;
  text-align: left;
}

.moduleTitle {
  font-size: 1.2rem;
  margin: 0 25px 5px 0;
}

.moduleDescription {
  color: #000;
  line-height: 25px;
  margin-bottom: 0;
  text-align: left;
}

.titleUnderline {
  border-top: 2px solid $text-color;
  border-radius: 0;
  display: block;
  width: 40px;
}

.arrowIcon {
  margin-top: 5px;
  height: 20px;
  width: 20px;
  transform: rotate(90deg);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &.flip {
    transform: rotate(-90deg);
  }
}

.unclickable {
  pointer-events: none;
}
