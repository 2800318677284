// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.targetAudience {
  text-align: center;
  & > h2 {
    font-size: 3.2em;
    margin: 0 auto;
    max-width: 600px;
  }
}

.audienceImages {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 60px 0;
}

.imageContainer {
  border-radius: 20px;
  box-shadow: $shadow-light;
  cursor: pointer;
  position: relative;
  width: 40%;
  &:hover {
    box-shadow: $shadow;
    border-radius: 50px;
    & .imageInfo {
      background: rgba(0, 0, 0, 0.8);
    }
  }
}

.image {
  border-radius: 20px;
  height: 100%;
  width: 100%;
}

.imageInfo {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 20px 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 20px 40px;
  right: 0;
  left: 0;
  text-align: left;
  & > h3 {
    font-size: 0.95rem;
    margin: 0;
    text-transform: uppercase;
  }
  & > p {
    font-size: 0.95rem;
    line-height: 25px;
    margin-bottom: 0;
  }
}

.getStartedBtn {
  border-bottom: 1px solid #fff;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 15px;
  display: flex;
  align-items: center;
  & > svg {
    fill: #fff;
    margin-left: 15px;
    height: 18px;
    width: 18px;
  }
}

.sellingPoints {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.point {
  margin: 0 20px;
  width: 25%;
  & > h3 {
    font-size: 1.1rem;
  }
  & > p {
    color: $text-color-light;
    font-size: 1rem;
    line-height: 27px;
  }
  & > svg {
    fill: $primary;
    height: 40px;
    width: 40px;
  }
}

.testimonial {
  margin-top: 4rem;
  text-align: center;
  & > h3 {
    font-size: 1.4rem;
    max-width: 700px;
    margin: auto;
    line-height: 40px;
  }
  & > h4 {
    color: $text-color-light;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}

.startFreeTrialBtn {
  font-size: 0.95rem;
  margin: 30px auto;
}

@media only screen and (max-width: 700px) {
  .targetAudience > h2 {
    font-size: 2em;
  }

  .imageContainer {
    margin-bottom: 40px;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .imageInfo {
    padding: 20px;
    & > h3 {
      font-size: 0.85rem;
    }
    & > p {
      font-size: 0.85rem;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .getStartedBtn {
    font-size: 0.85rem;
    margin-top: 10px;
  }

  .point {
    margin: 0 0 15px 0;
    width: 100%;
    & > h3,
    p {
      font-size: 0.9rem;
    }
    & > svg {
      height: 35px;
      width: 35px;
    }
  }

  .testimonial {
    & > h3 {
      font-size: 1rem;
      line-height: 25px;
      max-width: 300px;
    }
    & > h4 {
      font-size: 0.8rem;
    }
  }
}
