// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: linear-gradient(100deg, #f1f6fc, #efdff4);
  padding: 60px 0;
}

.title {
  font-size: 2.8rem;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 60px auto;
  line-height: 3.6rem;
}

.benefits_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px 24px;
  max-width: 1200px;
  margin: auto;
}

.benefit_card {
  background: $white;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 3%);
  display: flex;
  flex-direction: column;
}

.benefit_name_container {
  box-shadow: $shadow-emphasize;  
  padding: 30px;
  border-radius: 20px 20px 0 0;
  height: 180px;
}

.icon {
  height: 6rem;
  width: 6rem;
}

.benefit_name {
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 20px 0 0 0;
}

.benefit_details_list {
  padding: 30px;
  & > li {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 10px;
  }
}

.list_item_name {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.list_item_description {
  color: #7A8297;
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 6px 0 20px 0;
}

.learn_more_button {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 1.1rem;
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.arrow_icon {
  fill: #fff;
  height: 0.8rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 0.8rem;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 2rem;
  }

  .title {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 44px;
  }

  .benefits_container {
    grid-template-columns: repeat(1, 1fr);
  }

  .benefit_name_container {
    height: fit-content;
  }

  .icon {
    height: 4rem;
    width: 4rem;
  }
}