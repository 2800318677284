.modalBoxConfirmOrder {
  height: 100vh;
  width: 450px;
  margin: 0 0 0 auto;
  border-radius: 0;
}

.modalContainer {
  padding: 20px;
}

// ------------------------------------- Responsive -------------------------------------
@media only screen and (max-width: 450px) {
  .modalBoxConfirmOrder {
    width: 100vw;
  }
}
