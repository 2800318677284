// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

.container {
  border-radius: 4px;
  display: flex;
  padding: 0px 15px;
  font-size: 1.1rem;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  width: fit-content;
  height: 40px;
}

.option {
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  &.selected {
    color: $info;
    font-weight: bold;
  }
}
