// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.list {
  margin-top: 20px;
}

.listItem {
  margin: 10px 0px;
  width: 100%;
  height: 200px;
}

.imageContainer {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  color: white;
}

.image {
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.rewardLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.seeAllRewardsBtn {
  background: $info;
  color: #fff;
  padding: 5px 10px;
}
