// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
$modal-width: 450px;

.shop_name {
  color: $skipli-red;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
}

.shop_address {
  display: flex;
  margin-bottom: 1.6rem;
  & > svg {
    fill: $info;
    height: 1rem;
    margin-right: 0.6rem;
    width: 1rem;
  }
}

.submittedMsg {
  font-size: 1rem;
  width: 80%;
  margin: 10px auto 30px auto;
  line-height: 22px;
}

.viewMenuButton {
  background: $skipli-red !important;
  border-radius: 100px;
  padding: 0.8rem 1.2rem !important;
  margin-bottom: 1.2rem !important;
  text-transform: capitalize !important;
  width: 100%;
}

.checkOutButton {
  @extend .viewMenuButton;
  background: $skipli-red !important;
  font-weight: bold;
  justify-content: space-between !important;
}

.viewPastOrdersButton {
  @extend .viewMenuButton;
  background: transparent !important;
  border: 2px solid #000;
  box-shadow: none !important;
  color: #000 !important;
}

.add_more_items {
  @extend .viewMenuButton;
  background: transparent !important;
  border: 2px solid #000;
  color: #000;
  justify-content: flex-start;
}

.orders_icon {
  height: 1.2rem;
  margin-right: 0.6rem;
  width: 1.2rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.plus_icon {
  height: 0.9rem;
  margin-right: 0.6rem;
  width: 0.9rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

// -------------- Price --------------
.priceContainer {
  display: flex;
  align-items: baseline;
}

.totalPrice {
  margin-right: 10px;
}

.subText {
  font-size: 0.9rem;
  color: #8f95a3;
}

.bagIcon {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  & > svg {
    height: 60px;
    width: 60px;
  }
}

// ------------------------------------- Responsive -------------------------------------
@media only screen and (min-width: $modal-width) {
  .modal {
    border-radius: 0 !important;
    bottom: 0 !important;
    margin-right: 0 !important;
    top: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: $modal-width !important;
    min-width: $modal-width !important;
  }
}
