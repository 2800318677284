// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$item-card-border-radius: 10px;
$text-area-padding: 20px;

.container {
  padding: 4rem 0;
  text-align: center;
}

.heading {
  font-size: 2.4rem;
  margin: 0 0 4rem 0;
}

.menuItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  max-width: 1400px;
  margin: auto;
  padding: 0 30px;
}

.itemContainer {
  box-shadow: 0 4px 24px 0 hsla(0,0%,5%,.05);
  border-radius: $item-card-border-radius;
  border: 1px solid transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    border-color: #d4dae0;
  }
}

.itemImage {
  border-radius: $item-card-border-radius $item-card-border-radius 0 0;
  height: 20vw;
  object-fit: cover;
  width: 100%;
}

.text_area {
  padding: $text-area-padding;
  padding-bottom: 0;
  text-align: left;
}

.item_name,
.item_description {
  line-height: 140%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.item_name {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.item_description {
  font-weight: 400;
  margin: 14px 0 0 0;
  opacity: 0.6;
}

.add_item_button {
  background: none;
  color: #000;
  font-weight: 500;
  margin: 0 auto 0 0;
  padding: $text-area-padding;
  padding-bottom: 30px;
  &:hover {
    background: none;
    box-shadow: none;
  }
  & > svg {
    height: 14px;
    width: 14px;
    margin-left: 10px;
  }
}

.seeFullMenuBtn {
  font-size: 18px;
  margin-top: 80px;
  padding: 16px 80px;
  & > svg {
    fill: #fff;
    height: 1.2rem;
    margin-left: 0.6rem;
    width: 1.2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .menuItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .itemImage {
    height: 38vw;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 2rem 0;
  }

  .heading {
    margin-bottom: 2rem;
  }

  .menuItems {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
  }

  .itemImage {
    height: 300px;
  }

  .item_description {
    display: none;
  }

  .seeFullMenuBtn {
    padding: 16px;
    width: 86vw;
    margin-top: 50px;
    & > svg {
      height: 18px;
      width: 18px;
    }
  }
}
