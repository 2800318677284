// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

.datePicker {
  border-radius: 4px;
  padding: 0px 15px;
  height: 40px;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  & > div {
    border: none !important;
  }
}

.datePicker
  > div
  > .react-datetime-picker__clock.react-datetime-picker__clock--open {
  border: none !important;
  border-radius: 10px;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;

  & > time > div {
    border: none;
  }
}

.hideDateInput {
  & > div > div {
    & > input[name="month"] {
      // display: none;
      visibility: hidden;
    }
    & > input[name="day"] {
      visibility: hidden;
      // display: none;
    }
    & > input[name="year"] {
      visibility: hidden;
      // display: none;
    }
    & > span:first-of-type {
      display: none;
    }
    & > span:nth-of-type(2) {
      display: none;
    }
  }
}

.hideTimeInput {
  & > div > div {
    & > input[name="hour12"] {
      display: none;
    }
    & > input[name="minute"] {
      display: none;
    }
    & > select[name="amPm"] {
      display: none;
    }
    & > span:nth-of-type(4) {
      display: none;
    }
    & > span:nth-of-type(5) {
      display: none;
    }
    & > span:last-of-type {
      display: none;
    }
  }
}

.calendar {
  font-family: $global-font-family;
  font-size: 0.9rem;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}
