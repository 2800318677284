// BUTI DINERS, INC. All right Reserved ©

.list {
  display: flex;
  overflow: auto;
  padding-bottom: 5px;
  padding: 0 0px 0px 5px;
}

.listItem {
  margin: 0 14px 14px 0;
}

.desktopList {
  @extend .list;
  margin-top: 1.2rem;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 36px 50px;
  display: grid;
  list-style: none;
  overflow: visible;
}

@media only screen and (max-width: 900px) {
  .desktopList {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .desktopList {
    grid-gap: 0;
    margin-top: 0;
  }
}
