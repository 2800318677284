// BUTI DINERS, INC. All right Reserved ©

.mobileBackgroundImg {
  background: #fff;
  height: auto;
  min-height: 100%;
  &.admin {
    display: flex;
  }
}

.menuSkeletonContainer {
  max-width: 1240px;
  margin: 2rem auto auto auto;
}
