// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.navOption {
  color: $text-color;
  font-size: 0.94rem;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: $skipli-red;
  }
}

.isNew {
  background: $skipli-red;
  color: #fff;
  border-radius: 4px;
  font-size: 0.65rem;
  font-weight: bold;
  margin-left: 2px;
  padding: 2px 5px;
}

.getStarted {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  color: $white;
  font-weight: 600;
  padding: 12px 20px;
  transition: $transition;
  &:hover {
    color: #fff;
  }
}

.orderingPlatform {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 0;
  &:hover > svg {
    fill: $primary;
  }
  & > svg {
    height: 18px;
    width: 18px;
    margin-left: 8px;
  }
}

.footer {
  margin-top: 0 !important;
}

@media only screen and (max-width: 1100px) {
  .getStarted {
    margin: auto !important;
    width: 200px;
  }

  .orderingPlatform {
    margin-top: 30px !important;
    position: relative;
  }
}
