// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.customTipContainer {
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customTipAmount {
  font-size: 0.9rem;
  height: inherit;
  margin: 0px 0.4rem 0px;
}

.message {
  color: $dark-grey;
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin-bottom: 0;
}

.selected > svg {
  fill: $skipli-red;
}
