// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~components/WebsiteBuilder/style.module.scss";

.menuIconContainer {
  display: none;
}

.shopHeader {
  background: #fff;
  box-shadow: $shadow-light;
  height: $website-header-height;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 500;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  padding: 0 40px;
}

.shopNameContainer {
  display: flex;
  align-items: center;
}

.shopLogo {
  height: 40px;
  margin-right: 1rem;
}

.shopName {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0;
  &.light {
    color: #fff;
  }
  &.dark {
    color: #000;
  }
}

.menuIcon {
  height: inherit;
  width: inherit;
  &.light {
    fill: #fff;
  }
  &.dark {
    fill: #000;
  }
}

.navigation {
  display: flex;
}

.navOption {
  background: inherit;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  padding: 14px !important;
  text-transform: uppercase;
  &:hover {
    box-shadow: none;
  }
}

@media only screen and (max-width: 1200px) {
  .menuIconContainer {
    display: block;
    height: 1.4rem;
    width: 1.4rem;
  }

  .navigation {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .content {
    padding: 1rem 1.6rem;
  }

  .shopHeader {
    height: auto;
  }

  .shopNameContainer {
    flex-direction: column;
    align-items: baseline;
  }

  .shopLogo {
    height: 1.6rem;
  }

  .shopName {
    font-size: 1.4rem;
  }
}
