// BUTI Corp All right Reserved ©
// Son That Ton
// sonton@gatech.edu

.sectionContainer {
  border-bottom: 1px solid #b2bec3;
  padding-bottom: 20px;
  margin-bottom: 30px;

  &:last-child {
    border-bottom: none;
  }
}

.sectionLabel {
  text-transform: capitalize;
  margin: 0;
  font-size: 1.3rem;
}

.sectionDescription {
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 1rem;
}

.allergyOptionsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footerButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showDishesButton {
  background: #00b894;
  width: 70vw;
  border-radius: 8px;
  height: 7vh;
  font-size: 1.4rem;
  color: #fff;
}

.saveInfoButton {
  font-size: 1.2rem;
  padding: 0;
  color: #0984e3;
}

.arrowIcon {
  height: 20px;
  transform: rotate(180deg);
  width: inherit;
  margin-left: -10px;
}
