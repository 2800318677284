// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$focus-shadow: 0 0 0 1px $primary, 0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 0 0 2px $primary;

$error-shadow: 0 0 0 1px $warning, 0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 0 0 2px $warning;

.zipCodeField {
  height: 29px;
}

.inputField {
  font-size: 0.98rem;
  font-weight: 500;
  height: inherit;
}

.field {
  margin-bottom: 10px;
  &.email,
  &.name {
    margin-bottom: 20px;
  }
}

.useForNextCheckout {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.confirmCardButton {
  font-weight: 500;
  height: 40px;
  width: 100%;
  margin: auto auto 20px auto;
  text-transform: uppercase;
}

.icon {
  fill: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.StripeElement {
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 6px;
  min-height: 25px;
  padding: 11px 15px 5px 15px;
  width: inherit;
}

.otherCardInfoContainer {
  display: flex;
  margin-top: 15px;
}

.otherCardInfo {
  @extend .StripeElement;
  height: 25px;
  margin-right: 15px;
  width: 20%;
}

.StripeElementFocus {
  border-color: transparent;
  box-shadow: $focus-shadow;
  -webkit-box-shadow: $focus-shadow;
}

.StripeElementInvalid {
  border-color: transparent;
  box-shadow: $error-shadow;
  -webkit-box-shadow: $error-shadow;
}
