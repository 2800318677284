// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.introductionBackground {
  background-image: url("../../../assets/Images/landingPageImage.jpg");
  padding-bottom: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contentContainer {
  padding-top: 120px;
}

.introductionBox {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  color: #fff;
  padding: 30px;
  margin-left: -40px;
  width: 500px;
}

.mainMessage {
  font-size: 3rem;
  line-height: 60px;
  margin: 0;
  text-transform: capitalize;
}

.description {
  font-size: 1.2rem;
  line-height: 35px;
  margin: 20px 0 50px 0;
}

.circleCheck,
.point {
  display: flex;
  align-items: center;
}

.point {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.circleCheck {
  border-radius: 100%;
  justify-content: center;
  margin-right: 15px;
  height: 29px;
  width: 29px;
  background: #fff;
  & > svg {
    fill: #000;
    height: 13px;
    width: 13px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
}

.button {
  font-size: 1.2rem;
  height: 75px;
}

.startFreeStoreBtn {
  @extend .button;
  height: 75px;
  margin-right: 20px;
  width: 250px;
}

.learnMoreBtn {
  @extend .button;
  background-color: transparent;
  border: 2px solid #fff;
  font-weight: 500;
  width: 225px;
  &:hover {
    background: #fff;
    color: #000;
    & .freeConsultation {
      color: #000;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .introductionBox {
    margin-left: 0;
  }
}

@media only screen and (max-width: 700px) {
  .contentContainer {
    padding-top: 90px;
  }

  .introductionBox {
    background: rgba(0, 0, 0, 0.7);
    border-radius: $global-border-radius;
    margin: 0 -15px;
    padding: 20px 15px;
    width: inherit;
  }

  .mainMessage {
    font-size: 1.6rem;
    line-height: 40px;
  }

  .description {
    font-size: 1.1rem;
    line-height: 30px;
    margin: 15px 0 30px 0;
  }

  .point {
    font-size: 1.1rem;
  }

  .circleCheck {
    height: 25px;
    width: 25px;
  }

  .buttons {
    margin: 40px 0 20px 0;
    justify-content: center;
  }

  .button {
    font-size: 1rem;
    margin: 0 0 20px 0;
    height: 45px;
    width: 100%;
  }
}
