// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

.contentContainer {
  padding: 100px 0;
}

.callToAction {
  font-size: 2.5rem;
  line-height: 1.4;
  text-align: center;
  max-width: 60rem;
  margin: 0 auto 50px auto;
  padding: 0 20px;
}

.form {
  margin: 0 auto;
  text-align: center;
  max-width: 50em;
}

.message {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #333333;
  padding: 0 20px;
}

.jobContactEmail {
  color: #0984e3;
  text-decoration: none;
}

.opptyBoxesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

@media only screen and (max-width: 500px) {
  .callToAction {
    font-size: 2rem;
  }

  .message {
    font-size: 1.2rem;
  }
}
