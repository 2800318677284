// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.thank_you_msg {
  background: $info;
  border-radius: 10px;
  color: #fff;
  line-height: 24px;
  margin: 3rem auto auto auto;
  max-width: 368px;
  padding: 10px 16px;
  text-align: center;
  & > svg {
    height: 1rem;
    margin-right: 6px;
    width: 1rem;
    fill: #fff;
  }
}

.feedback_card {
  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 10px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin: 30px auto auto auto;
  max-width: 400px;
}

.card_header, .card_body {
  padding: 20px;
}

.card_header {
  border-bottom: 1px solid #edf2f9;
  color: $info;
  font-size: 1rem;
  font-weight: bold;
  padding: 26px 20px;
  text-align: center;
}

.link {
  color: #000;
}

.social_media_button {
  border: 1px solid #e3ebf6;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-bottom: 0.75rem;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
}

.social_media_logo {
  height: 22px;
  margin-right: 10px;
  width: 22px;
}