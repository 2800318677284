// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  border-top: 0.5px solid #dadada;
  padding: 4rem 0;
}

.content {
  max-width: 1200px;
  margin: auto;
  padding: 0 30px;
}

.contact_info_row {
  display: flex;
  justify-content: space-between;
}

.column {
  padding-right: 2rem;
}


.columnHeading {
  font-size: 18px;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

// --------------- Logo Column ---------------

.shopLogoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 40px;
}

.shopLogo {
  height: 3.2rem;
  margin-right: 20px;
}

.skipliLink {
  color: $text-color;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-decoration: none;
}

.skipliWebsite {
  color: $skipli-red;
  margin-left: 0.2rem;
}

.madeWithLove {
  display: flex;
  align-items: center;
  & > svg {
    fill: $skipli-red;
    height: 1rem;
    margin: 0 0.3rem;
    width: 1rem;
  }
}

// --------------- Social media Column ---------------
.social_media_column {
  @extend .column;
}

.social_media_icons_container {
  display: flex;
  flex-wrap: wrap;
}

.mediaLink {
  display: flex;
  align-items: center;
  color: inherit;
  opacity: 0.5;
  margin: 0 18px 1rem 0;
  text-decoration: none;
  text-transform: capitalize;
  transition: $transition1;
  &:hover {
    opacity: 1;
    transform: scale(1.02);
  }
}

.social_media_icon {
  height: 20px;
  width: auto;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

// --------------- Contact Info Column ---------------

.contactInfoColumn {
  @extend .column;
}

.contactInfo {
  display: flex;
  margin-bottom: 1rem;
  transition: $transition1;
  & > svg {
    height: 20px;
    margin-right: 14px;
    width: 20px;
  }
}

.contactInfoLink {
  color: inherit;
  text-decoration: none;
}

.contact_info_value {
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.2rem;
  margin: 0;
}

// --------------- Business Hours Column ---------------

.businessHoursColumn {
  @extend .column;
}

.day {
  font-size: 0.95rem;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 2rem 0;
  }

  .contact_info_row {
    flex-direction: column;
  }

  .column {
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }

  .shopLogoContainer {
    font-size: 22px;
  }

  .shopLogo {
    height: 2.4rem;
  }
}
