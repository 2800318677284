// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.modalBox {
  max-height: 94vh;
  width: 500px;
}

.heading {
  text-align: center;
  margin: 0rem;
}

.paragraph {
  font-weight: 500;
  line-height: 21px;
  margin: 1rem 0;
}

.centerWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.benefitContainer {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 1.2rem;
}

.benefitIcon {
  height: 5rem;
  width: 5rem;
  margin-right: 1.2rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.learn_more_button {
  background: $info;
  color: #fff;
  margin-top: 20px;
  width: 260px;
  & > svg {
    fill: #fff;
    height: 1rem;
    margin-left: 8px;
    width: 1rem;
  }
}