// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  display: flex;
  justify-content: center;
}

.dot {
  animation: container 0.6s infinite alternate;
  background-color: #000;
  border-radius: 100px;
  margin: 2px;
  opacity: 1;
  height: 8px;
  width: 8px;
}

@keyframes container {
  to {
    opacity: 0.2;
    // transform: translateY(-2px);
  }
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}