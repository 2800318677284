// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$checkbox-border-radius: 4px;

.container {
  display: flex;
  align-items: center;
  padding-right: 5px;
  transition: $transition1;
}

.alignStart {
  align-items: flex-start;
}

.label {
  line-height: 1.2rem;
  text-align: left;
}

.description {
  color: $dark-grey;
  margin: 0.4rem 0 0 0;
  text-align: left;
}

.common {
  background-color: #fff;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px !important;
  margin: 0 8px 0 0;
  width: 18px !important;
  min-width: 18px;
  &.checked {
    border-color: $skipli-red;
    & .checkIcon,
    & .circle {
      visibility: visible;
    }
  }
}

.checkbox {
  @extend .common;
  border-radius: $checkbox-border-radius;
  &.checked {
    background-color: $skipli-red;
  }
}

.checkIcon {
  fill: #fff;
  height: 12px;
  width: 12px;
  visibility: hidden;
}

.radioButton {
  @extend .common;
  border-radius: 100px;
  &.checked {
    background-color: #fff;
  }
}

.circle {
  background: $skipli-red;
  border-radius: 100px;
  height: 12px !important;
  width: 12px !important;
  max-width: 10px;
  min-width: 10px;
  visibility: hidden;
}

.readOnly {
  background-color: $grey !important;
}
