// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.contentContainer {
  padding: 60px 0 30px 0;
  & > h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

.statistics {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}

.card {
  margin: 0 20px;
  text-align: center;
  width: 28%;
}

.statCard {
  @extend .card;
  & > h3 {
    color: $skipli-red;
    font-size: 5.4rem;
    margin: 0;
    line-height: 4rem;
  }
  & > p {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.approachCard {
  @extend .card;
  & > h3 {
    font-size: 1.4rem;
    margin: 0;
  }
  & > p {
    color: $text-color-light;
    font-size: 1rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 700px) {
  .contentContainer {
    padding: 40px 0;
  }

  .statistics {
    flex-direction: column;
  }

  .card {
    width: 100%;
  }

  .statCard {
    & > h3 {
      font-size: 3rem;
      line-height: 50px;
    }
    & > p {
      font-size: 1.2rem;
      margin: 8px 0 30px 0;
    }
  }
}
