// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$navigation_bar_height: 54px;

.navigation_bar {
  border-bottom: 1px solid #EEF1F6;
  background: #fff;
  height: $navigation_bar_height;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 50;
}

.navigation_bar_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}

.skipli_logo {
  height: 1.6rem;
  & > svg {
    height: inherit;
  }
}

.container {
  display: flex;
  height: calc(100vh - $navigation_bar_height);
}

.iframe {
  height: calc(100vh - $navigation_bar_height);
  width: calc(100vw - 400px);
}

.order_details_container {
  background: #fff;
  border-right: 1px solid #EEF1F6;
  overflow-y: scroll;
  width: 400px;
}

.illustration_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 400px);
  & > img {
    width: 40vw;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 60px;
  }

  .order_details_container {
    width: 100%;
  }

  .illustration_container {
    display: none;
  }
}
