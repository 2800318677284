// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~components/WebsiteBuilder/style.module.scss";

.container {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;
}

.content {
  max-width: 70vw;
  margin: auto;
  padding-top: $website-header-mobile-height + 70px;
}

.navOption {
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  text-align: center;
}
