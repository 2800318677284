// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~styles/mainWebsite.module";

.pageContainer {
  padding-top: 56px;
}

.sectionContainer {
  @extend .sectionContainer;
}

.callButton {
  @extend .letsChatBoxSecondaryBtn;
}

.getFreeDemoBtn {
  @extend .letsChatBoxMainBtn;
}

.heroImage {
  border-radius: $global-border-radius;
}

.partnersContainer {
  margin: auto;
  max-width: 1200px;
  padding: 0 30px;
}

.partners {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: 50px 0;
  margin: 80px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.partner > img {
  filter: grayscale(1);
  height: 35px;
}

@media only screen and (max-width: 1100px) {
  .stat {
    & > strong {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 700px) {
  .partners {
    margin: 40px 0;
    padding: 1rem 0;
  }

  .partner {
    margin: 1rem 0;
    text-align: center;
    width: 45%;
    & > img {
      height: 2rem;
    }
  }
}
