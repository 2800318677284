// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$focus-shadow: 0 0 0 1px $primary, 0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 0 0 2px $primary;

$error-shadow: 0 0 0 1px $warning, 0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 0 0 2px $warning;

// ---------------- FORM FIELD ----------------

.field {
  border: 1.2px solid #000;
  background: #fff;
  border-radius: $global-border-radius;
  min-height: 28px;
  padding: 8px 16px;
  width: inherit;
  & > input,
  textarea {
    font-weight: 500;
    padding: 0;
  }
  & > input {
    line-height: 30px;
  }
  & > textarea {
    line-height: 24px;
  }
  &.focused {
    border: none;
    box-shadow: $focus-shadow;
    -webkit-box-shadow: $focus-shadow;
  }
  &.invalid {
    border: none;
    box-shadow: $error-shadow;
    -webkit-box-shadow: $error-shadow;
  }
  &.readOnly {
    background: $grey;
    border: none;
  }
}

.label {
  color: $text-color-light;
  white-space: pre-wrap;
  max-width: inherit;
  margin-bottom: 0.6rem;
}

.input {
  background: inherit;
  color: $text-color;
  font-family: $global-font-family;
  font-size: inherit;
  padding-top: 0;
  width: 100%;
  &::placeholder {
    color: #4f4f4f;
    opacity: 0.6;
  }
}

// ---------------- FORM FOOTER ----------------

.formFooter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5px;
}

.dangerText {
  color: $danger;
  font-size: 0.8rem;
  width: 85%;
}

.requiredText {
  color: #636e72;
  font-size: 0.7rem;
}
