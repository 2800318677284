// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.button {
  background: $skipli-red;
  justify-content: flex-start;
  padding: 10px 15px;
  width: 100%;
}

.iconContainer {
  height: 42px;
  margin-right: 15px;
  width: 42px;
  background: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    height: 32px;
    width: 32px;
  }
}

.heading {
  font-size: 0.84rem;
  margin: 0;
}

.callToAction {
  display: flex;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.84rem;
  & > svg {
    height: 15px;
    margin-left: 8px;
    width: 15px;
    fill: #fff;
  }
}
