// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$radio-button-shadow: 0 3px 5px -1px rgba(245, 129, 129, 0.2),
  0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

.paymentMethod {
  background: $grey;
  border-radius: $global-border-radius;
  color: #000;
  margin-bottom: 20px;
  padding: 15px 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.selected {
    border: 2px solid $skipli-red;
    background: #fff;
    color: #000;
  }
  &:hover {
    box-shadow: none;
  }
}

.methodInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cardBrand {
  font-weight: bold;
  text-transform: uppercase;
}

.checkIcon {
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  -webkit-box-shadow: $radio-button-shadow;
  box-shadow: $radio-button-shadow;

  &.selected {
    background: $skipli-red;
    box-shadow: none;
    -webkit-box-shadow: none;
    & > svg {
      fill: #fff;
      height: 18px;
      width: 18px;
    }
  }
}

.removeButton {
  height: 16px;
  width: 16px;
  & > svg {
    fill: $danger;
    height: 16px;
    width: 16px;
  }
}

.dialogDescription {
  font-size: 1rem;
  line-height: 22px;
}

.dialogButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.button {
  color: #fff;
  height: 40px;
  margin: 0 14px;
  width: 130px;
}

.removeItemButton {
  @extend .button;
  background: $skipli-red;
}

.keepItemButton {
  @extend .button;
  background: $primary;
  font-weight: bold;
}

.modal {
  max-width: 400px;
}