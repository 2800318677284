// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.modalBoxConfirmOrder {
  height: 100vh;
  width: 450px;
  margin: 0 0 0 auto;
  border-radius: 0;
}

.modalContainer {
  padding: 20px;
}

.modalTitle {
  margin: 0 0 1.2rem 0;
}

.orderDeliveryTypeSelectors {
  margin-top: 2rem;
}

.expansionPanelTitle {
  font-size: 1.1rem;
}

.closeModalIcon {
  fill: $text-color;
  height: 1.2rem;
  width: 1.2rem;
  transform: rotate(180deg);
}

// ------------------------------------- Responsive -------------------------------------
@media only screen and (max-width: 450px) {
  .modalBoxConfirmOrder {
    width: 100vw;
  }
}
