// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  -webkit-box-orient: vertical;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: $grey;
  padding: 200px 73px;
  flex-flow: column;
  overflow: hidden;
  display: flex;
  -webkit-box-direction: normal;
}

.slashy {
  position: absolute;
  transform: rotate(10deg);
  width: 4000px;
  height: 4000px;
  top: -730px;
  right: calc(50% + 215px);
  z-index: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(129, 129, 141, 0.01) 0px 56px 70px,
    rgba(129, 129, 141, 0.06) 0px 1px 12px, rgb(245, 245, 245) 0px 56px 70px;
}

.content {
  display: flex;
  -webkit-box-direction: normal;
  width: 100%;
  max-width: 880px;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: justify;
  justify-content: space-between;
  z-index: 1;
  flex-flow: row;
}

.leftColumn {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
  width: 40%;
}

.rightColumn {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  align-items: flex-start;
  width: 38%;
  flex-flow: column;
}

@media only screen and (max-width: 1000px) {
  .slashy {
    display: none;
  }

  .content {
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-flow: column;
  }

  .leftColumn,
  .rightColumn {
    width: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 30px;
  }

  .leftColumn {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    width: 100%;
  }

  .rightColumn {
    width: 100%;
  }
}
