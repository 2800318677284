// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.title {
  font-size: 94px;
  line-height: 100%;
  margin: 0 0 20px;
  text-align: center;

  &>span {
    background: linear-gradient(90deg, #FF5846 0%, #eb1700 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.description {
  color: #5f7494;
  font-size: 18px;
  font-weight: 400;
  line-height: 135%;
  margin: 0 auto 80px auto;
  max-width: 600px;
  text-align: center;
  width: 100%;
}

.feature_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 100px;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.feature_video_container {
  width: 41%;
}

.feature_description {
  width: 48%;
}

.feature_video {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
  max-width: 100%;
  padding: 20px;
}

.video_player {
  border-radius: inherit;
  clip-path: inset(1px 1px);

  &>video {
    border-radius: inherit;
  }
}

.feature_label {
  color: #9B9FA5;
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  margin: 0 0 25px;
}

.feature_title {
  color: #18191C;
  font-size: 35px;
  font-weight: 700;
  line-height: 125%;
  margin: 0 0 25px;
}

.check_icon {
  height: 16px;
  margin-right: 16px;
  width: 16px;

  &>svg {
    fill: $skipli-red;
    height: inherit;
    width: inherit;
  }
}

.list_item {
  display: flex;
  align-items: center;
  color: #7A8297;
  font-size: 20px;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 20px;
}

.startNowBtn {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 1.2rem !important;
  padding: 16px 30px !important;
  margin: 50px auto 0 0;
}

.arrowIcon {
  fill: #fff;
  height: 1rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 1rem;
}


@media only screen and (max-width: 900px) {
  .title {
    font-size: 56px;
  }

  .feature_container {
    border-bottom: 1px solid #C4C4C4;
    flex-direction: column !important;
    margin-bottom: 50px;
    padding-bottom: 20px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .feature_video_container,
  .feature_description {
    width: 100%;
  }

  .feature_video_container {
    margin-bottom: 50px;
  }
}