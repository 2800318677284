// BUTI Corp All right Reserved ©
// Son That Ton
// sonton@gatech.edu

.sectionContainer {
  border-bottom: 1px solid #b2bec3;
  padding-bottom: 50px;
  margin-bottom: 50px;

  &:last-child {
    border-bottom: none;
  }
}

.sectionLabel {
  text-transform: capitalize;
  margin: 0;
  font-size: 2rem;
}

.sectionDescription {
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 1rem;
}

.allergyOptionsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.allergyOption {
  width: 20%;
  margin: 0 8px 25px 8px;
}

.footerButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showDishesButton {
  background: #00b894;
  width: 70vw;
  border-radius: 10px;
  height: 90px;
  font-size: 40px;
  color: #fff;
}

.saveInfoButton {
  font-size: 2.5em;
  padding: 0;
  color: #0984e3;
}
