// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$select_box_column_width: 30px;

.modifier {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  & > * {
    transition: all 100ms ease-in-out 0s;
  }
}


// --------------------- Select Box Column ---------------------
.selectBoxColumn {
  width: $select_box_column_width;
}

.selectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 2px solid #000;
  height: 18px;
  margin: 0;
  width: 18px;
  &.checked {
    border-color: $skipli-red;
    & .circle,
    .checkIcon {
      visibility: visible;
    }
  }
}

.checkbox {
  @extend .selectBox;
  border-radius: 4px;
  &.checked {
    background-color: $skipli-red;
  }
}

.checkIcon {
  fill: #fff;
  height: 12px;
  width: 12px;
  visibility: hidden;
}

.radioButton {
  @extend .selectBox;
  border-radius: 100px;
}

.circle {
  background: $skipli-red;
  border-radius: 100px;
  height: 12px;
  width: 12px;
  visibility: hidden;
}

// --------------------- Modifiers ---------------------

.modifierInfoColumn {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  width: calc(100% - $select_box_column_width);
}

.modifierName {
  line-height: 140%;
  font-weight: 400;
  text-align: left;
}

.modifierPrice {
  color: $dark-grey;
  font-size: 0.8rem;
  white-space: nowrap;
}

.modifierDescription {
  color: $dark-grey;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  margin: 4px 0 0 0;
  text-align: left;
}

.showMoreButton {
  text-align: center;
  padding-top: 10px;
  & > button {
    background: $info;
    width: 140px;
    padding: 5px 0;
    color: #fff;
    font-weight: bold;
    border-radius: 100px;
  }
}

.requiredLabel {
  color: $skipli-red;
  font-weight: bold;
  text-transform: uppercase;
}
