// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../../../assets/Images/contactlessDining.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0;
}

.image {
  margin-bottom: 2rem;
  width: 100vw;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.titleText {
  color: #fff;
  max-width: 500px;
  text-align: left;
  & > h1 {
    font-size: 3rem;
    line-height: 3.4rem;
    margin: 0;
  }
}

.paragraph {
  font-size: 1.2rem;
  line-height: 30px;
  max-width: 480px;
  margin: 30px 0;
}

.buttons {
  display: flex;
}

.button {
  font-size: 1.1rem;
  margin-top: 20px;
  padding: 16px 30px;
}

.learnMoreBtn {
  @extend .button;
  color: $skipli-red !important;
  margin-left: 0;
  margin-right: 20px;
}

.seeActionBtn {
  @extend .button;
  background: $skipli-red;
  margin-left: 0;
}

.playIcon {
  fill: #fff;
  margin-right: 15px;
  height: 18px;
  width: 18px;
}

@media only screen and (max-width: 700px) {
  .titleText {
    color: #000;
    & > h1 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  .paragraph {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  .button {
    font-size: 0.9rem;
    margin-top: 0;
    padding: 12px 15px;
  }

  .playIcon {
    margin-right: 10px;
    height: 14px;
    width: 14px;
  }
}
