// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  margin: 10px 0;
}

.weekdayContainer {
  display: flex;
  margin-bottom: 0.6rem;
  opacity: 0.6;
  &.isToday {
    font-weight: bold;
    opacity: 1;
  }
}

.weekday {
  margin-right: 1rem;
  text-transform: capitalize;
  width: 80px;
}
