// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$website-thumbnail-height: 270px;

.container {
  padding-bottom: 8rem;
}

// --------------------- Page Introduction ---------------------

.image {
  height: 440px;
  object-fit: cover;
  width: 100%;
}

.pageIntro {
  display: flex;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 2rem;
}

.heading {
  font-size: 4rem;
  margin: 0 6rem 0 0;
}

.paragraph {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}

.callToActionButton {
  border-bottom: 4px solid $skipli-red;
  border-radius: 0;
  font-size: 1.2rem;
  padding-bottom: 0.6rem;
  &:hover {
    padding: 0 1rem 0.6rem 1rem;
  }
}

// --------------------- Websites Gallery ---------------------

.websitesGalleryContainer {
  max-width: 1200px;
  margin: 100px auto;
  padding: 0 30px;
}

.websitesGallery {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  display: grid;
  list-style: none;
  overflow: visible;
  margin-top: 6rem;
}

.websiteContainer {
  cursor: pointer;
  & > a {
    color: $text-color-light;
    text-decoration: none;
  }
  &:hover {
    .websiteThumbnailImg {
      filter: opacity(0.8);
      transition: $transition;
    }
  }
}

.websiteThumbnailImg {
  box-shadow: $shadow-light;
  border-radius: $global-border-radius;
  background: #fff;
  height: $website-thumbnail-height;
  & > img {
    border-radius: $global-border-radius;
    height: inherit;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.websiteNameContainer {
  display: flex;
  align-items: center;
}

.websiteName {
  font-size: 1.5rem;
}

.newWindowIcon {
  fill: $info;
  height: 1.2rem;
  margin-left: 16px;
  width: 1.2rem;
}

.requestDesign {
  background: $primary;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  height: 60px;
  width: 250px;
}

.seeMoreExamples {
  background: #273c63;
}

@media only screen and (max-width: 900px) {
  .header {
    padding: 4rem 0;
  }

  .headerText {
    padding: 0 20px;
  }

  .websitesGallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  // --------------------- Page Introduction ---------------------

  .image {
    height: 200px;
  }

  .pageIntro {
    flex-direction: column;
  }

  .heading {
    font-size: 2rem;
    line-height: 2rem;
    margin-right: 0;
  }

  // --------------------- Websites Gallery ---------------------

  .websitesGalleryContainer {
    margin: 0 0 100px 0;
  }

  .websitesGallery {
    grid-template-columns: repeat(1, 1fr);
  }

  .websiteContainer {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .heading {
    font-size: 1.8rem;
  }

  .requestDesign {
    width: 100%;
  }
}
