// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

body {
  background-color: #f9fbfd;
}

.container {
  padding: 40px;
}

.shop_logo {
  height: 82px;
  margin: auto;
  width: 82px;
  & > img {
    height: inherit;
    width: inherit;
  }
}

.shop_name {
  color: $info;
  font-size: 1.1rem;
  line-height: 26px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}