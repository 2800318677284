// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.menuNameContainer {
  background: $grey;
  position: -webkit-sticky;
  position: sticky;
  margin: 0 -20px 2rem -20px;
  top: 48px;
  z-index: 40;
}

.menuNameContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 1.4rem;
}

.menuName {
  border-bottom: 2px solid #000;
  font-size: 0.94rem;
  font-weight: 500;
  margin: 0;
  padding-bottom: 0.2rem;
  width: fit-content;
  text-transform: capitalize;
}

.filterButton {
  display: flex;
  align-items: center;
  background: none;
  color: #000;
  padding: 0;
  margin-right: 0;
  & > svg {
    height: 1.2rem;
    margin-right: 0.4rem;
    width: 1.2rem;
  }
  &:hover {
    box-shadow: none;
  }
}

.selectCategoryButton {
  background: none;
  color: #000;
  margin-right: 0;
  padding: 10px 0 10px 2rem;
  & > svg {
    height: 1.4rem;
    margin-right: 0.4rem;
    width: 1.4rem;
  }
  &:hover {
    box-shadow: none;
  }
}

.filtersCount {
  margin-left: 0.6rem;
  background: $primary;
  color: #fff;
  display: flex;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  justify-content: center;
  border-radius: $global-border-radius;
}

.seeAllItemBtn {
  margin-top: 1rem;
  padding: 0.2rem 0.8rem;
}

.categoryNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 2.4rem;
}

.categoryName {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
  padding-right: 1rem;
  text-transform: capitalize;
}

.seeAllBtn {
  display: flex;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  & > span {
    margin-right: 0.2rem;
  }
  & > svg {
    fill: #000;
    height: 0.9rem;
    margin-left: 0.2rem;
    width: 0.9rem;
  }
}

.backBtn {
  background: #fff;
  box-shadow: $button-shadow;
  color: $primary;
  height: 36px;
  width: 15vw;
  border-radius: $global-border-radius;
  justify-content: center;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 4.2rem;
  left: 24px;
  margin: auto;
  & > svg {
    fill: $primary;
    height: 1.2rem;
    width: 1.2rem;
    transform: rotate(180deg);
  }
}

.hidden {
  visibility: hidden;
}
