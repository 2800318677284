// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.customerHeaderContainer {
  position: sticky;
  right: 0;
  left: 0;
  background: $primary-lightest;
  top: 0;
  z-index: 400;
}

.desktopNavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 70px;
  max-width: 1024px;
  margin: 0 auto;
}

.skipliLogoIcon {
  height: 70px;
  width: 70px;
}
