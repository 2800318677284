// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #fffbf5;
  padding: 2.4rem 0 6rem;
}

.heading {
  font-size: 3.2rem;
  margin: 0 auto 2rem auto;
  max-width: 44rem;
  padding: 0 2rem;
  text-align: center;
}

.paragraph {
  color: $dark-grey;
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 2rem;
  padding: 0 2rem;
  text-align: center;
}

.phone_number {
  color: #000;
  border-bottom: 2px solid $skipli-red;
  font-weight: bold;
  padding-bottom: 0.2rem;
  text-decoration: none;
  &:hover {
    padding: 0 0.4rem 0.2rem 0.4rem;
  }
}

@media only screen and (max-width: 700px) {
  .heading {
    font-size: 1.8rem;
  }
}
