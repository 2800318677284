// BUTI DINERS, INC. All right Reserved ©

.modalClassname {
  height: 100vh;
  width: 400px;
  margin: auto 0 auto auto;
}

.contentContainer {
  height: auto;
  position: relative;
  padding: 20px 20px 60px 20px;
}

@media only screen and (max-width: 500px) {
  .modalClassname {
    width: 100vw;
  }
}
