// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.modal {
  max-height: 84vh;
}

.profile_image_modal_content_container {
  text-align: center;
}

.large_profile_image {
  height: 350px;
  margin-top: 30px;
  width: 350px;
}

.row {
  border-bottom: 1px solid $border-color-dark;
  cursor: pointer;
  padding: 20px;
  position: relative;
}

.row_title {
  margin-top: 0;
  margin-bottom: 10px;
}

.text {
  line-height: 24px;
}

.gray_text {
  @extend .text;
  color: #494949;
}

.icon {
  background: $border-color-dark;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  height: 26px;
  width: 26px;
  & > svg {
    fill: #000;
    height: 14px;
    width: 14px;
  }
}

.phone_number_button {
  background: $border-color-dark;
  border-radius: 100px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 8px 16px;
  width: fit-content;
  & > svg {
    fill: #000;
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }
}

.driver_profile_image {
  cursor: pointer;
  height: 48px;
  border-radius: 100px;
  width: 48px;
  object-fit: cover;
  position: absolute;
  right: 20px;
  top: 10px;
}

.vehicle {
  display: flex;
  align-items: center;
}

.car_icon {
  height: 16px;
  margin-right: 8px;
  width: 16px;
  & > svg {
    fill: #494949;
    height: inherit;
    width: inherit;
  }
}

.arrow {
  position: absolute;
  right: 20px;
  top: 40%;
  height: 18px;
  width: 18px;
  & > svg {
    fill: #494949;
    height: inherit;
    width: inherit;
  }
}

.order_status {
  @extend .delivery_status;
  border-radius: 8px !important;
  color: #000;
  text-transform: none !important;
  &.closed {
    background: $primary;
    color: #fff; 
  }
}

.delivery_address {
  font-weight: bold;
  margin: 14px 0;
}

.delivery_status {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 8px 20px;
  margin: 14px 0;
  text-transform: capitalize;
  width: fit-content;
  &.delivered {
    background: #009048;
    color: #fff;
  }
  &.cancelled  {
    background: $skipli-red;
    color: #fff;    
  }
}

.iframe_container {
  display: none;
}

.iframe {
  height: 100vw;
  margin: 80px 0 0 -20px;
  width: 100vw;
}

.pickup_notice {
  @extend .delivery_status;
  background: #006aff;
  border-radius: 100px;
  color: #fff;
  text-transform: none;
}

@media only screen and (max-width: 700px) {
  .large_profile_image {
    height: 80vw;
    width: 80vw;
  }

  .iframe_container {
    display: block;
  }
}
