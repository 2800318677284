// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.background {
  background: linear-gradient(192.67deg, rgba(1, 157, 255, 0.05) 9.17%, rgba(1, 157, 255, 0) 85.7%);
}

.section_container {
  padding-top: 80px;
}

.title {
  background: linear-gradient(90deg, #FF5846 0%, #eb1700 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 94px;
  line-height: 100%;
  margin: 0 0 60px;
  text-align: center;
}

.benefit_container {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 3%);
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 105px 60px 100px 85px;

  &:nth-child(1),
  &:nth-child(4) {
    background: linear-gradient(100deg, rgba(246, 251, 255), rgba(228, 245, 255));
  }

  &:nth-child(2) {
    background: linear-gradient(100deg, rgba(241, 246, 252), rgba(239, 223, 244));
  }

  &:nth-child(3) {
    background: linear-gradient(100deg, rgba(243, 250, 253), rgba(226, 252, 243));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.benefit_text_container {
  width: 50%;
}

.benefit_video_container {
  width: 43%;
}

.benefit_title {
  color: $skipli-red;
  font-size: 60px;
  font-weight: 700;
  line-height: 100%;
  margin: 0 0 55px;
}

.list_item {
  margin-bottom: 24px;

  &>h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    margin: 0 0 10px;
  }

  &>p {
    color: #7A8297;
    font-size: 18px;
    line-height: 140%;
    margin: 0;
  }
}

.replaces_container {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: $shadow;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  margin: 50px 0;
  padding: 30px;
  width: fit-content;
}

.replaces_list {
  display: flex;
  align-items: center;
}

.company_name {
  padding: 0 20px;
}

.startNowBtn {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 1.2rem !important;
  padding: 16px 30px !important;
  margin: 40px auto 0 0;
}

.arrowIcon {
  fill: #fff;
  height: 1rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 1rem;
}

.benefit_video {
  aspect-ratio: 1 / 1;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
  max-width: 100%;
  padding: 20px;
}

.video_player {
  aspect-ratio: 1 / 1;
  border-radius: inherit;
  clip-path: inset(1px 1px);

  &>video {
    aspect-ratio: 1 / 1;
    border-radius: inherit;
  }
}

@media only screen and (max-width: 900px) {
  .title {
    font-size: 56px;
    margin: 0 0 65px;
  }

  .benefit_container {
    border-radius: 0;
    flex-direction: column-reverse;
    margin: 0 -24px 80px -24px;
    padding: 25px 25px 35px;
  }

  .benefit_text_container,
  .benefit_video_container {
    width: 100%;
  }

  .benefit_title {
    font-size: 30px;
    margin-top: 50px;
  }

  .list_item {
    margin-bottom: 24px;

    &>h4 {
      font-size: 20px;
    }
  }

  .replaces_container {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    width: auto;
  }

  .replaces_list {
    margin-top: 10px;
  }

  .company_name {
    font-size: 16px;
    margin-right: 20px;
    padding: 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}