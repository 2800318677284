// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.modal {
  border-radius: 0;
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  background-color: #ffffff;
}
.subTitle {
  color: #6c6c6c;
}
.title {
  padding-left: 2rem;
  padding-top: 2rem;
}
.contentContainer {
  height: 100%;
  padding: 0;
}
.cartContainer {
  display: flex;
  gap: 10em;
  padding: 2rem;
  background-color: #ffffff;
}
.price_mid {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 28px;
  display: none;
}
.price,
.cart_item_name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 28px;
}
.cartLeft {
  flex: 2;
}

.cartRight {
  flex: 1;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item {
  border-radius: 8px;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}

.product {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.productImage {
  width: 100px;
  height: auto;
}

.planDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
}

.planInfo h4 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.planPrice {
  font-weight: bold;
  font-size: 1rem;
}

.summary h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.monthlyFeeDetail,
.paymentDetails {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.paymentRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.monthlyFee {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.promoCode {
  border-radius: 4px;
  border: 1px solid $skipli-red;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

.purchaseButton {
  width: 100%;
  padding: 0.8rem;
  background-color: $skipli-red;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 3rem;
}

.payment_method_icon {
  margin: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.quantityAndRemove {
  display: flex;
  align-items: center;
  gap: 10px;
}

.removeButton {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 14px;
}

.removeButton:hover {
  text-decoration: underline;
}

.purchaseButton:hover {
  background-color: rgb(249, 61, 18);
}
.name_container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 700px) {
  .cartContainer {
    display: block;
  }
  .price_mid {
    display: block;
  }
  .price {
    display: none;
  }
}
