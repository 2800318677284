// BUTI Corp All right Reserved ©

@import "~styles/variables";

.modalBox {
  border-radius: 0 !important;
  height: 100% !important;
  width: 100% !important;
  margin-top: 0;
}

.buttonsContainer {
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 20px;
}

.button {
  font-size: 1rem;
  margin: 1rem auto 3rem auto;
  padding: 0.6rem;
  width: 40vw;
  & > svg {
    height: 0.8rem;
    margin-right: 0.4rem;
    width: 0.8rem;
  }
}

.applyButton {
  @extend .button;
  & > svg {
    fill: #fff;
  }
}

.clearButton {
  @extend .button;
  background: $border-color-dark;
  color: #000;
}
