// BUTI DINERS, INC. All right Reserved ©

.greetingMsg {
  padding: 0 20px;
  & > h1 {
    font-size: 2rem;
  }
  & > p {
    font-size: 1.2rem;
    line-height: 30px;
  }
}

.background {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background: linear-gradient(to top, rgb(0, 164, 189), rgb(0, 189, 165));
  background: #fff;
}

.skipliLogoContainer {
  margin: auto;
  & > svg {
    height: 200px;
    width: 200px;
  }
}
