// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  height: 45px;
  width: 260px;
  padding: 0 20px;
  margin: auto auto 20px auto;
}

.addCardButton {
  @extend .button;
  background: $info;
  color: #fff;
  justify-content: flex-start;
}
