// BUTI DINERS, INC. All right Reserved ©

.container {
  padding-top: 6rem;
  display: flex;
  align-items: center;
}

.textCol {
  width: 36%;
  & > h2 {
    font-size: 1.4rem;
  }
  & > p {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

.imgCol {
  text-align: right;
  width: 64%;
}

.image {
  width: 50vw;
}

.getStartedBtn {
  margin: 2rem 0 0 0;
  & > svg {
    fill: #fff;
    height: 1rem;
    margin-left: 1rem;
    width: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .textCol {
    margin-bottom: 2rem;
    text-align: center;
    width: 60%;
  }

  .imgCol {
    text-align: center;
    width: 100%;
  }

  .getStartedBtn {
    margin: 2rem auto 0 auto;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding-top: 2rem;
  }

  .textCol {
    width: 100%;
  }

  .image {
    width: 80vw;
  }
}
