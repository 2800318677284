// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #000;
  padding: 40px 60px;
}

.content_container {
  align-items: center;
  display: flex;
}

.skipli_link {
  color: #fff;
  text-decoration: none;
}

.learn_more_button {
  background: $info;
  color: #fff;
  margin-right: 0;
  & > svg {
    fill: #fff;;
    height: 1rem;
    margin-left: 8px;
    width: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 40px 30px;
  }

  .content_container {
    align-items: flex-start;
    flex-direction: column;
    font-size: 1rem;
  }

  .learn_more_button {
    margin-top: 20px;
    margin-left: 0;
  }
}
