// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.tabsContainer {
  margin-bottom: 2rem;
}

.tabLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 20px;
  margin-right: 10px;
  width: 20px;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.selected > svg {
  fill: $skipli-red !important;
  & > g > path {
    stroke: $skipli-red !important;
  }
}