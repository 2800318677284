// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.loadingMsg {
  background: #000;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 2rem;
  padding: 15px;
  text-align: center;
}
