// BUTI DINERS, INC. All right Reserved ©

.agreeButton {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.disclaimer {
  color: #757575;
  line-height: 22px;
}

.checkbox {
  height: 25px;
  width: 25px;
  border: 1px solid #e0e0e0;
  border-radius: inherit;
  margin-right: 15px;
  &.checked {
    background: #000;
    border-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.checkIcon {
  height: 16px;
  width: 16px;
  fill: #fff;
}
