// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.quantityCalculator {
  height: 40px;
}

.quantityNumber {
  min-width: 20px;
  max-width: 20px;
}
