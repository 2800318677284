// BUTI DINERS, INC. All right Reserved ©

.link {
  display: flex;
}

.skipliLogo {
  height: 55px;
  width: 55px;
}
