// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #fff;
  border-radius: $global-border-radius;
  box-shadow: $shadow-light;
  margin-bottom: 2rem;
  padding: 30px 40px;
}

@media only screen and (max-width: 900px) {
  .container {
    border-radius: $global-border-radius;
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    border-radius: 0;
  }
}
