// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.heading {
  font-size: 1.2rem;
}

.category {
  background: #fff;
  border-bottom: 1px solid $border-color-dark;
  border-radius: 0;
  color: #000;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.4rem;
  padding: 14px 0;
  text-align: left;
  width: 100%;
  &:hover {
    box-shadow: none;
  }
}

.categoryName {
  padding-right: 1rem;
}

.itemsCount {
  font-weight: 400;
  text-transform: lowercase;
  white-space: nowrap;
}
