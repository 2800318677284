// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.letsChatBox {
  background: $skipli-red;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 50px;
}

.letsChatText {
  & > h2 {
    font-size: 2rem;
    margin: 0;
  }
  & > p {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6rem;
    margin-bottom: 0;
  }
}

.button {
  background: #fff;
  border-radius: 100px;
  color: $skipli-red;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  height: 45px;
  width: 200px;
  // Display
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookDemoButton,
.clickCallButton {
  @extend .button;
}

.bookDemoButton {
  box-shadow: $button-shadow;
  margin-bottom: 1.4rem;
}
.icon {
  stroke: rgb(100%, 0%, 0%) !important;
}
.callLink {
  color: $skipli-red;
  text-decoration: none;
  & > svg {
    fill: $skipli-red !important;
    stroke: rgb(100%, 0%, 0%) !important;
    height: 1rem;
    margin-right: 0.4rem;
    width: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .letsChatBox {
    border-radius: $global-border-radius;
    flex-direction: column;
    margin-top: 0;
    padding: 40px 20px;
  }

  .letsChatText {
    & > h2 {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  .buttons {
    margin-top: 2rem;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
