// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.dialogButtonsContainer {
  text-align: center;
}

.message {
  color: #757575;
  line-height: 22px;
  text-align: center;
  margin-bottom: 100px;
}

.extraFeesMsg {
  color: #757575;
  font-size: 0.5rem;
  margin: 5px 0 0 0;
}

.button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  padding: 0 20px;
  margin: auto auto 20px auto;
  text-transform: uppercase;
}

.useStoredMethodButton {
  @extend .button;
  background: $skipli-red;
  margin-bottom: 0;
  justify-content: space-between;
}

.addCardButton {
  @extend .button;
  background: $primary;
  margin-top: 1rem;
  justify-content: flex-start;
}

.payNow {
  display: flex;
  align-items: center;
}

.totalAmount {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 0;
}

.shieldIcon {
  fill: #fff;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.plusIcon {
  fill: #fff;
  height: 16px;
  margin-right: 14px;
  width: 16px;
}
