// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.inputFieldContainer {
  margin-bottom: 1.2rem;
}

.instruction {
  margin-top: 0;
}

.phoneNumberInst {
  @extend .instruction;
  color: $dark-grey;
  font-size: 0.8rem;
  margin-bottom: 2rem;
}
