// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.viewReceiptButton {
  background: $info;
  border-radius: $global-border-radius;
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}
