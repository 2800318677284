// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  display: flex;
  padding: 80px 0;
}

.reverse {
  flex-direction: row-reverse;
}

.image_column,
.text_column {
  padding: 0 1rem;
  

  width: 50%;
}

.hero_image {
  width: 100%;
}

.text_content {
  padding: 0 6rem;
}

.heading {
  font-size: 3rem;
  line-height: 3.6rem;
  margin: 0;
}

.paragraph {
  color: #000;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  margin: 30px 0;
}

// ----------------------------------------------------
// List

.listContainer {
  margin-top: 2rem;
}

.listItem {
  align-items: flex-start;
  display: flex;
  cursor: pointer;
  margin-bottom: 1.4rem;
  &:hover {
    & .itemName {
      color: $skipli-red;
    }
  }
}

.iconContainer {
  // Display
  display: flex;
  align-items: center;
  justify-content: center;
  // Other
  border: 1px solid #000;
  border-radius: 100px;
  height: 1.2rem;
  margin-right: 0.8rem;
  min-width: 1.2rem;
  max-width: 1.2rem;
}

.closeIcon {
  height: 0.55rem;
  width: 0.55rem;
}

.plusIcon {
  height: 0.7rem;
  width: 0.7rem;
}

.itemName {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin: -5px 0 0 0;
  &.selected {
    color: $skipli-red;
  }
}

.itemDescription {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0.8rem 0 0 0;
  max-width: 400px;
}

// ----------------------------------------------------
// Action button

.actionButton {
  background: none;
  border-bottom: 4px solid $skipli-red;
  border-radius: 0;
  color: #000;
  font-size: 1.2rem;
  margin: 2rem 0 0 0;
  padding: 0 0 0.6rem 0;
  &:hover {
    box-shadow: none;
    padding: 0 1rem 0.6rem 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column-reverse;
    padding: 30px 0;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .image_column,
  .text_column {
    width: inherit;
  }

  .image_column {
    margin-bottom: 2rem;
  }

  .text_content {
    padding: 0 2rem;
  }

  .heading {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .paragraph {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 1rem 0 2rem 0;
  }

  .itemName {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
