// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$desktop-navbar-height: 65px;
$mobile-navbar-height: 55px;

.container {
  position: fixed;
  right: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid $grey;
  top: 0;
  z-index: 400;
}

.content {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: $desktop-navbar-height;
  max-width: 1700px;
  margin: 0 auto;
}

.skipliLogoContainer {
  cursor: pointer;
  height: $mobile-navbar-height;
  width: 5.4rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.menuIcon {
  cursor: pointer;
  visibility: hidden;
  & > svg {
    fill: $text-color;
    height: 25px;
    width: 25px;
  }
}

.desktopNavbarOptions {
  margin-left: 50px;
  position: relative;
  width: 100%;
}

.mobileNavbarOptions {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  & > button {
    font-size: 1.2rem;
    margin: 0 0 1.4rem 0;
  }
}

@media only screen and (max-width: 1300px) {
  .content {
    height: $mobile-navbar-height;
    justify-content: space-between;
    padding: 0 20px;
  }

  .desktopNavbarOptions {
    display: none;
  }

  .menuIcon {
    visibility: visible;
  }
}

@media only screen and (max-width: 700px) {
  .skipliLogoContainer {
    height: inherit;
    width: 5rem;
  }
}
