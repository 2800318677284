// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.heading {
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
  & > span {
    font-size: 1.6rem;
  }
}

.description {
  font-size: 1.2rem;
  line-height: 35px;
}

.tableCell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color-dark;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 18px 0;
  text-transform: capitalize;
  width: 100%;
}

.cellLabel {
  padding-right: 46px;
}

.openFreeAccountBtn {
  color: #000;
  background-color: transparent;
  border-bottom: 4px solid $skipli-red;
  border-radius: 0;
  font-size: 1.2rem;
  margin: 30px 0 0 0;
  padding: 0.4rem 0;
  &:hover {
    box-shadow: none;
    padding: 0.4rem 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .heading {
    font-size: 2rem;
    & > span {
      font-size: 1rem;
    }
  }

  .description {
    font-size: 1rem;
    line-height: 26px;
    margin-top: 10px;
  }

  .tableCell {
    font-size: 1rem;
  }

  .openFreeAccountBtn {
    margin-top: 1rem;
  }
}
