// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.calculatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background: $grey;
  border-radius: 10px;
  padding: 0 0.4rem;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.quantityNumber {
  margin: 0px 10px 0px;
  min-width: 50px;
  font-size: 1rem;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  height: 2rem;
  line-height: 0;
  width: 2rem;
}

.icon {
  height: 0.8rem;
  width: 0.8rem;
}
