// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.menuContainer {
  max-width: 1240px;
  margin: auto;
  padding: 30px 30px 160px 30px;
}

.categoryContainer {
  border-bottom: 1px solid $border-color-dark;
  padding-bottom: 30px;
  &:last-child {
    border-bottom: none;
  }
}

.categoryName {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 20px 0 0 0;
  text-transform: capitalize;
}

.footerContainer {
  background: $grey;
  height: 4rem;
}

.footerContent {
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1240px;
  margin: auto;
  padding: 0 30px;
}

.link {
  color: $text-color-light;
  font-weight: 400;
  margin-right: 1rem;
  text-decoration: none;
  &:hover {
    opacity: 0.68;
    transition: $transition;
  }
}

.copyright {
  font-weight: 500;
}
