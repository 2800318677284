// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.topSignUpContainer {
  background: #fff;
  border-bottom: 2px solid $skipli-red;
  display: none;
  padding: 0.6rem 0;
  position: sticky;
  top: 56px;
  z-index: 20;
}

.topButtons {
  display: flex;
  padding: 0 1rem;
}

.button {
  border-radius: 100px;
  padding: 0.8rem 1rem;
  width: 48%;
}

.topCallButton {
  @extend .button;
  background: $info;
  color: $white;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: $white;
    height: 0.8rem;
    margin-right: 0.6rem;
    width: 0.8rem;
  }
}

.topSignUpButton {
  @extend .button;
  background: $skipli-red;
  font-weight: bold;
}

.signUpButton {
  background: $skipli-red;
  border: 2px solid $skipli-red;
  border-radius: 100px;
  color: #fff;
  margin: 0 2rem 0 0;
  font-weight: bold;
}

.referencesContainer {
  padding-top: 10rem;
}

.reference {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  margin-bottom: 2rem;
  zoom: 96%;
  &.reversed {
    flex-direction: row-reverse;
  }
  & > img {
    height: 26rem;
  }
}

.secondRef,
.thirdRef {
  margin-bottom: 9rem;
}

.fourthRef {
  margin-bottom: 8rem;
}

.fifthRef {
  margin-bottom: 6rem;
}

.text {
  background: $skipli-red;
  color: #fff;
  height: fit-content;
  padding: 2.8rem;
  & > p {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin-top: 0;
  }
}

.shopNameContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.shopName {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  margin: 0;
  text-transform: uppercase;
}

.viewMenuButton {
  background: #fff;
  border-radius: 100px;
  color: $skipli-red;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 0;
  padding: 0.8rem 2rem;
}

.howWeHelp {
  color: $skipli-red;
  margin: auto;
  width: 40rem;
  & > h2 {
    font-size: 2.4rem;
  }
}

.benefit {
  margin-bottom: 2rem;
  & > h3 {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
  & > p {
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }
}

@media only screen and (max-width: 1300px) {
  .reference {
    zoom: 94%;
  }
}

@media only screen and (max-width: 1200px) {
  .reference {
    zoom: 82%;
  }
}

@media only screen and (max-width: 1300px) {
  .topSignUpContainer {
    display: block;
    left: 0;
    position: fixed;
    right: 0;
  }

  .referencesContainer {
    padding-top: 10rem;
  }

  .signUpButton {
    display: none;
  }

  .reference {
    zoom: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .referencesContainer {
    padding-top: 0;
  }

  .signUpButton {
    margin-right: 1rem;
    padding: 0.8rem 1rem;
  }

  .learnMoreButton {
    padding: 0.8rem 1rem;
  }

  .reference {
    flex-direction: column !important;
    margin-bottom: 0;
    padding: 0;
    & > img {
      height: auto;
      width: 100vw;
    }
  }

  .shopNameContainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .viewMenuButton {
    margin-top: 1rem;
    margin-left: 0;
    width: 100%;
  }

  .shopName {
    margin-top: 0;
  }

  .text {
    margin: 0 !important;
    max-width: 100% !important;
    min-width: auto !important;
    padding: 2rem 1.2rem;
    transform: none !important;
    & > p {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.6rem;
    }
  }

  .howWeHelp {
    padding: 0 2rem;
    width: inherit;
    & > h2 {
      font-size: 1.4rem;
      margin-top: 4rem;
    }
  }

  .benefit {
    & > h3 {
      font-size: 1.1rem;
    }
    & > p {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
}
