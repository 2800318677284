// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #fafafb;
  padding: 60px 0;
}

.title {
  font-size: 2.8rem;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 80px auto;
  line-height: 3.6rem;
}

.diagram_container {
  margin: auto;
  text-align: center;
  width: 86%;
  & > img {
    width: inherit;
  }
}

.steps_list {
  display: none;
}

.step_container {
  font-size: 1.1rem;
  display: flex;
}

.image {
  height: auto;
  margin-right: 20px;
  width: 60px;
  & > img {
    height: inherit;
    width: inherit;
  }
}

.step_count {
  color: #5f7494;
  font-weight: 600;
  margin-bottom: 8px;
}

.step_name {
  line-height: 1.5rem;
}

.dot {
  background: #7A8297;
  height: 10px;
  margin: 30px auto;
  opacity: 0.4;
  width: 10px;
}

.get_live_demo_button {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 1.2rem !important;
  margin: 70px auto 40px auto;
  padding: 16px 50px !important;
}

.arrow_icon {
  fill: #fff;
  height: 0.8rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 0.8rem;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 2rem;
  }

  .title {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 44px;
  }

  .diagram_container {
    display: none;
  }

  .steps_list {
    display: block;
  }

  .get_live_demo_button {
    width: 100%;
  }
}