// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.link_container {
  border-bottom: 1px solid $border-color-dark;
  padding: 30px 0;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:last-of-type {
    border-bottom: none;
  }
}

.article {
  text-decoration: none;
}

.pub_date {
  color: $dark-grey;
  font-weight: 400;
}

.article_preview_info {
  display: flex;
  align-items: stretch;
  margin-top: 20px;
}

.article_preview_text {
  // flex: 1 1 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
}  

.article_title {
  color: $black;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
}

.article_description {
  color: $dark-grey;
  display: -webkit-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.featured_image {
  border-radius: 10px;
  height: inherit;
  margin-left: 80px;
  object-fit: contain;
  width: 500px;
}

.view_full_article_button {
  background: none;
  color: $info;
  font-weight: 500;
  margin-left: 0;
  padding-left: 0;
  text-transform: initial;
  &:hover {
    box-shadow: none;
  }
}

.new_window_icon {
  height: 14px;
  margin-left: 12px;
  width: 14px;
  & > svg {
    fill: $info;
    height: inherit;
    width: inherit;
  }
}

.article_extra_info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 40px;
}

.tag_post {
  background-color: $grey;
  border-radius: 100px;
  color: #000;
  margin-right: 20px;
  padding: 6px 14px;
}

.read_time {
  color: $dark-grey;
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  .article_preview_info {
    flex-direction: column-reverse;
  }

  .article_preview_text {
    margin-top: 20px;
    padding-right: 0;
  }

  .article_title {
    font-size: 20px;
  }

  .featured_image {
    margin-left: 0;
    width: 100%;
  }

  .article_extra_info {
    margin-top: 30px;
    position: relative;
  }
}
