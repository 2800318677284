// BUTI DINERS, INC. All right Reserved ©

.overlay {
  z-index: 100000;
}

.modalBox {
  border-radius: 20px;
  height: 250px;
  width: 250px;
}

.contentContainer {
  display: flex;
  justify-content: center;
}

.message {
  font-size: 1.5rem;
}
