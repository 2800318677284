// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  margin: auto;
  padding: 30px;
}

// ----------------------------------- Cover Image -----------------------------------

.desktop_cover_image {
  border-radius: 10px;
  height: 362px;
  width: 420px;
  object-fit: cover;
}

.mobile_cover_image_container {
  display: none;
  position: relative;
  height: 200px;
}

.mobile_cover_image {
  height: inherit;
  object-fit: cover;
  width: 100vw;
}

.mobile_cover_image_bottom_border {
  position: absolute;
  bottom: -11px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 60px 0 0 480px;
  border-color: transparent transparent transparent rgb(255, 255, 255);
  transform: rotate(360deg);
}

.mobile_shop_logo_container {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  box-shadow: $shadow-light;
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.mobile_shop_logo {
  object-fit: contain;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

// ----------------------------------- Shop Info -----------------------------------

.shop_introduction_container {
  padding: 24px 50px 0 0;
}

.fulfillment_methods_row {
  align-items: flex-start;
  display: flex;
  margin-top: 40px;
}

.fulfillment_method {
  cursor: pointer;
  margin-right: 30px;
  opacity: 0.4;
  &.selected {
    opacity: 1;
    border-bottom: 2px solid $skipli-red;
  }
}

.fulfillment_method_icon {
  height: 24px;
  width: auto;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.fulfillment_method_label {
  font-size: 16px;
  line-height: 1.25rem;
  margin: 10px 0;
}

.logoContainer {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  box-shadow: $shadow-light;
  bottom: -42px;
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.logo {
  object-fit: contain;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .container {
    display: block;
    padding: 0;
  }

  .desktop_cover_image {
    display: none;
  }

  .mobile_cover_image_container {
    display: block;
  }

  .shop_introduction_container {
    padding: 20px;
  }

  .fulfillment_methods_row {
    margin-top: 26px;
  }

  .fulfillment_method_icon {
    height: 20px
  }

  .fulfillment_method_label {
    font-size: 14px;
    margin: 6px 0;
  }
}
