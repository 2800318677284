// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.bannerContainer {
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1rem;
}

.infoIcon {
  fill: #fff;
  height: 1.2rem;
  margin-right: 0.8rem;
  width: 1.2rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.bannerText {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

@media only screen and (min-width: 700px) {
  .bannerText {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 900px) {
  .bannerContainer {
    margin-top: 48px;
  }
}
