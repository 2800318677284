// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: linear-gradient(100deg, #f6fbff, #e4f5ff);
  padding: 100px 30px;
}

.content {
  max-width: 1100px;
  margin: auto;
}

.row {
  display: grid;
  grid-gap: 0 30px;
}

.row_1 {
  grid-gap: 0 160px;
  grid-template-columns: 1fr 210px;
}

.row_1_paragraph {
  color: #5f7494;
  font-size: 26px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  & > span {
    color: $skipli-red;
    font-weight: bold;
  }
}

.get_free_demo_button {
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  font-size: 20px;
  font-weight: bold;
  height: 60px;
  line-height: 1.1;
  margin: 0 0 0 auto;
  padding: 19px 25px;
}

.row_2 {
  margin-top: 90px;
  grid-template-columns: repeat(4, 1fr);
}

.row_2_column {
  color: #14181e;
  font-size: 18px;
  line-height: 150%;
  & > h5 {
    color: #7a8297;
    font-size: inherit;
    font-weight: 600;
    line-height: 125%;
    margin: 0 0 10px;
  }
}

.row_3 {
  margin-top: 90px;
}

.link {
  font-size: inherit;
  margin-bottom: 8px;
  & > a {
    color: inherit;
    margin-right: 0;
    text-decoration: none;
  }
  &:hover {
    color: $skipli-red
  }
}

.socialMedia {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.socialMediaIcon {
  height: 2rem;
  width: 2rem;
  border: 2px solid $text-color-light;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: $transition;
  & > svg {
    fill: $text-color-light;
    height: 1rem;
    width: 1rem;
  }
  &:hover {
    border-color: $skipli-red;
    background: $skipli-red;
    & > svg {
      fill: #fff;
    }
  }
}

.row_4 {
  align-items: center;
  display: flex;
  margin-top: 90px;
}

.skipli_logo_container {
  width: 12%
}

.legal_links_container {
  align-items: center;
  display: flex;
  margin-left: 80px;
}

.legal_link {
  border-right: 1px solid #d7dbe4;
  padding: 0 10px;
  & > a {
    color: #7a8297;
    font-size: 20px;
    font-weight: 400;
    line-height: 125%;
    text-decoration: none;
  }
  &:last-of-type {
    border-right: none;
  }
  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
}

@media only screen and (max-width: 800px) {
  .row_1 {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  .get_free_demo_button {
    margin-left: 0;
    margin-right: auto;
  }

  .row_2 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-top: 60px;
  }

  .row_2_column {
    font-size: 20px;
  }

  .row_4 {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .legal_links_container {
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0;
  }

  .legal_link {
    border-right: none;
    margin-bottom: 14px;
    padding: 0;
  }

  .skipli_logo_container {
    margin-top: 40px;
    width: 36%;
  }
}
