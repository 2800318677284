// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.skeleton_button_container {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.skeleton_button {
  background-color: #fff;
  border-radius: 100px;
  box-shadow: $button-shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

.live_chat_loader_provider {
  display: none;
}