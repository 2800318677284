// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #000;
}

.contentContainer {
  color: #fff;
  padding: 4rem 0;
  text-align: center;
  & > h2 {
    font-size: 3.2em;
    margin: 0 auto;
    max-width: 500px;
  }
  & > p {
    font-size: 2em;
    line-height: 40px;
    margin: 30px auto;
    max-width: 500px;
  }
}

.merchantDashboardImg {
  width: 60vw;
}

.startSellingBtn {
  font-size: 1rem;
  height: 52px;
  margin-bottom: 60px;
  width: 400px;
  text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
  .contentContainer {
    & > h2 {
      font-size: 2em;
    }
    & > p {
      font-size: 1.7em;
      line-height: 30px;
    }
  }

  .merchantDashboardImg {
    width: 85vw;
  }

  .startSellingBtn {
    width: 300px;
  }
}
