// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.submitButton {
  background: $skipli-red;
  font-size: 14px;
  justify-content: space-between;
  height: 40px;
  width: 210px;
  padding: 0 15px;
}

.priceCol {
  display: flex;
  align-items: center;
}

.totalPriceBeforeSale {
  margin-right: 10px;
  opacity: 0.7;
}

.totalPriceAfterSale {
  font-weight: bold;
}
