// BUTI Corp All right Reserved ©

@import "~styles/variables";

.option {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color-dark;
  cursor: pointer;
  padding: 1.4rem 0;
}

.option_label_container {
  align-items: center;
  display: flex;
}

.option_icon {
  height: 1.5rem;
  margin-right: 1.4rem;
  width: 1.5rem;
  & > svg {
    fill: #000;
    height: inherit;
    width: inherit;
  }
}

.option_label {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.6rem;
}

.arrowIcon {
  height: 1rem;
  width: 1rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.divider {
  background: $grey;
  height: 0.8rem;
  margin: -1px -1rem 0 -1rem;
}

.skipli_logo {
  height: 2rem;
  margin: 2rem 0 1rem 0;
  & > svg {
    height: inherit;
    width: auto;
  }
}

.skipli_info_option {
  font-size: 1.1rem;
  padding: 1rem 0;
  & > a {
    color: #000;
  }
}

@media only screen and (min-width: 600px) {
  .modal {
    border-radius: 0;
    bottom: 0;
    height: 100%;
    max-height: 100%;
    right: auto;
  }
}
