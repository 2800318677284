// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.testimonialBox {
  border-top: 10px solid $skipli-red;
  box-shadow: $shadow;
  border-radius: $global-border-radius;
  padding: 100px 60px 60px 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textCol {
  padding-right: 2rem;
  width: 100%;
  & > p {
    font-size: 1.2rem;
    line-height: 30px;
  }
}

.profileImg {
  height: 180px;
  width: 180px;
  border-radius: 100px;
  position: absolute;
  top: -90px;
  background: #fff;
  box-shadow: $shadow-light;
}

.profileName {
  font-weight: 600;
}

.imageCol {
  width: 100%;
  & > img {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .testimonialBox {
    flex-direction: column;
    padding: 60px 30px 30px 30px;
  }

  .textCol,
  .imageCol {
    width: 100%;
  }

  .textCol {
    padding-right: 0;
    & > p {
      font-size: 0.9rem;
      line-height: 22px;
    }
    & > h3 {
      font-size: 0.9rem;
    }
  }

  .profileImg {
    height: 120px;
    width: 120px;
    top: -60px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
