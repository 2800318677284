// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$error-shadow: 0 0 0 1px $warning, 0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 0 0 2px $warning;

.label {
  color: $text-color-light;
  max-width: inherit;
  margin-bottom: 0.6rem;
  white-space: pre-wrap;
}

.input_field_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border: 1.2px solid #000;
  border-radius: 10px;
  cursor: pointer;
  line-height: 30px;
  min-height: 28px;
  padding: 8px 16px;
}

.required {
  border-color: transparent;
  box-shadow: $error-shadow;
  -webkit-box-shadow: $error-shadow;
}

.reduce_opacity {
  opacity: 0.6;
}

.arrow_icon {
  height: 1.2rem;
  width: 1.2rem;
  & > svg {
    height: inherit;
    width: inherit;
    transform: rotate(90deg);
  }
}

.us_state {
  border-bottom: 1px solid $border-color-dark;
  cursor: pointer;
  line-height: 1.4rem;
  padding: 14px 0;
}

.read_only {
  background: #f4f4f5;
  box-shadow: none;
  color: #000;
  pointer-events: none;
}
