// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #fafafb;
  padding: 60px 0;
}

.title {
  font-size: 2.4rem;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 60px auto;
  line-height: 3.6rem;
}

.logos_container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto auto auto auto;
  grid-gap: 28px 16px;
  max-width: 960px;
  margin: auto;
}

.logo_link {
  margin: auto;
  max-width: 100%;
}

.no_pointer {
  pointer-events: none;
}

.logo_img {
  filter: grayscale(1);
  height: 54px;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  vertical-align: middle;
  &:hover {
    filter: none;
  }
}

.gradient_overlay {
  background-image: linear-gradient(180deg, rgba(255,255,255,0), rgba(250,250,251,1));
  position: relative;
  display: block;
  height: 268px;
  margin-top: -254px;
}

// ----------------------------------------------
// Buttons

.buttons_container {
  display: flex;
  width: fit-content;
  margin: auto;
}

.button {
  font-size: 1.1rem !important;
}

.startNowBtn {
  @extend .button;
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%) !important;
  box-shadow: 0px 12.314px 36.9421px rgb(235 23 0 / 25%) !important;
  padding: 16px 26px !important;
}


.learnMoreBtn {
  @extend .button;
  border: 2px solid $skipli-red;
  box-shadow: none !important;
  color: $skipli-red !important;
  padding: 14px 24px !important;
  & > svg {
    fill: $skipli-red;
  }
}

.arrowIcon {
  fill: #fff;
  height: 1rem;
  margin-left: 0.4rem;
  margin-top: 2px;
  width: 1rem;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 60px 2rem;
  }

  .title {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 44px;
  }

  .logos_container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
  }

  .gradient_overlay {
    height: 500px;
    margin-top: -498px;
  }

  .buttons_container {
    flex-direction: column;
    margin-top: 14px;
  }

  .startNowBtn {
    margin: 0 0 40px 0;
    width: 100%;
  }

  .learnMoreBtn {
    margin: 0;
    width: 100%;
  }
}