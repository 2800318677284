// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  font-size: 0.86rem;
  margin-bottom: 80px;
  text-align: center;
}

.stripeBrand {
  margin-bottom: 0.2rem;
  text-align: center;
}

.links {
  margin-top: 0;
}

.link {
  color: $text-color;
  opacity: 0.8;
}
