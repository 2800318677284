// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.content_container {
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 30px;
}

.heading {
  font-size: 50px;
  margin-bottom: 30px;
  margin: 0;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  margin: 30px 0;
}