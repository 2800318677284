// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.slides_container {
  display: flex;
  margin: 0 -10rem 60px -10rem;
}

.slide {
  cursor: pointer;
  height: auto;
  margin: 0 30px;
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
}

.slides_navigation {
  align-items: center;
  display: flex;
  justify-content: center;
}

.arrow {
  height: 1.4rem;
  width: 1.4rem;
  & > svg {
    fill: #5f7494;
    height: inherit;
    width: inherit;
  }
}

.left_arrow {
  @extend .arrow;
  transform: rotate(180deg);
}

.right_arrow {
  @extend .arrow;
}

.circles_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.circle {
  background: #ced0d7;
  height: 12px;
  width: 12px;
  border-radius: 100px;
  margin-right: 1rem;
  &.active {
    background: $skipli-red;
  }
  &:first-child {
    margin-left: 2rem;
  }
  &:last-child {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .slides_navigation {
    align-items: flex-start;
  }

  .circle {
    margin-bottom: 0.8rem;
  }
}