// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$border-bottom: 1px solid rgba(144, 161, 189, 0.17);

// -----------------------------------------------------------
// Thumbnail Image
.thumbnailImgContainer {
  position: relative;
  height: 220px;
}

.thumbnailImgBottomBorder {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 60px 0 0 480px;
  border-color: transparent transparent transparent rgb(255, 255, 255);
  transform: rotate(360deg);
}

.thumbnailImg {
  height: inherit;
  object-fit: cover;
  width: 100vw;
}

.shop_logo_container {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  box-shadow: $shadow-light;
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.shop_logo {
  object-fit: contain;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

.shop_introduction_container {
  font-size: 0.8rem;
  padding: 1rem;
}
