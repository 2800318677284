// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  padding: 70px 0;
}

.content {
  align-items: center;
  display: flex;
  max-width: 1200px;
  margin: auto;
}

.graphic {
  width: 50%
}

.text_column {
  padding-left: 50px;
}

.guests_count {
  color: $skipli-red;
  font-size: 6rem;
  margin-bottom: 0;
}

.guests_served_with {
  font-size: 2.8rem;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 10px;
}

.skipli {
  color: $skipli-red;
  font-size: 3.4rem;
}

.description {
  color: #5f7494;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 2rem;
  }

  .content {
    flex-direction: column-reverse;
  }

  .graphic {
    margin-top: 30px;
    width: 100%;
  }

  .text_column {
    padding-left: 0;
  }

  .guests_count {
    font-size: 3rem;
    margin-top: 0;
  }

  .guests_served_with {
    font-size: 2.2rem;
    margin-top: 10px;
  }

  .skipli {
    font-size: 2.6rem;;
  }
}