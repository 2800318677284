// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.shop_logo_container {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  box-shadow: $shadow-light;
  position: absolute;
  bottom: 0;
  left: 16px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.shop_logo {
  object-fit: contain;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

.minimized {
  bottom: 0;
  left: 0;
  height: 40px;
  margin-left: 0.8rem;
  position: relative;
  width: 40px;
}

// -----------------------------------------------------------
// Shop Info

.shop_name {
  & > h1, h2 {
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 4rem;
    margin: 0;
  }
  & > h2 {
    color: $skipli-red
  }
}

.address, .ratings_container {
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 400;
}

.address {
  line-height: 1.5rem;
  margin-top: 16px;
  margin-bottom: 0;
}

.ratings_container {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.desktop_clock_icon {
  fill: #5e5e5e;
  height: 16px;
  margin-right: 0.4rem;
  width: 16px;
  & > svg {
    height: inherit;
    width: inherit;
  } 
}

.comingSoonButton {
  margin-top: 1rem;
}

.ratings {
  display: flex;
  align-items: center;
  & > svg {
    height: 16px;
    width: 16px;
    margin: 0 0.4rem;
  }
}

.dot {
  font-size: 10px;
  margin: 0 0.2rem;
}

.arrow_icon {
  height: 0.8rem;
  margin-left: 0.6rem;
  width: 0.8rem;
  & > svg {
    height: inherit;
    transform: rotate(90deg);
    width: inherit;
  }
}

.icon {
  fill: $info;
  height: 1rem;
  margin-right: 0.4rem;
  width: 1rem;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.shopAddress {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.openHours,
.closedToday {
  margin-left: 0.4rem;
}

.openHours {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  line-height: 18px;
}

.closedToday {
  background: $danger;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  padding: 6px 10px;
}

@media only screen and (max-width: 900px) {
  .shop_name > * {
    font-size: 26px !important;
    letter-spacing: 0.5px !important;
    line-height: 36px !important;
  }

  .address, .ratings_container {
    font-size: 14px;
    line-height: 18px;
  }

  .address {
    margin-top: 12px;
  }
}