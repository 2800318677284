// BUTI Corp All right Reserved ©
// Son That Ton
// john@buti.io

.payMethodContainer {
  display: flex;
  padding: 0 5px;
  align-items: center;
  opacity: 0.2;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &.selected {
    opacity: 1;
  }
}

.iconContainer {
  height: 40px;
  margin-right: 10px;
}

.methodIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > svg {
    height: 40px;
  }
}
