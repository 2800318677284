// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.pageContainer {
  padding-top: 66px;
  position: relative;
}

.sectionContainer {
  max-width: 1280px;
  margin: auto;
  padding: 50px 24px;
}

.letsChatBoxButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 45px;
  width: 200px;
  font-size: 1rem;
  font-weight: bold;
}

.letsChatBoxMainBtn {
  @extend .letsChatBoxButton;
  background: $primary;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  color: #fff;
}

.letsChatBoxSecondaryBtn {
  @extend .letsChatBoxButton;
  background: #fff;
  color: $primary;
  & > svg {
    height: 16px;
    width: 16px;
    fill: $primary;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 700px) {
  .pageContainer {
    padding-top: 55px;
  }

  .letsChatBoxButton {
    width: 100%;
  }
}
