// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$paddingLeftRight: 20px;

// ------------------------------------------------------------------------
// Layout styling

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}

.modal {
  background: #fff;
  border: none;
  border-radius: $global-border-radius;
  padding: 0;
  overflow: auto;
  outline: none;
  // Dimension
  height: fit-content;
  max-height: 70vh;
  width: fit-content;
  min-width: 30rem;
  // Position
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  margin: auto;
  z-index: 9999;
}

// ------------------------------------------------------------------------
// Content styling

.contentContainer {
  padding: $paddingLeftRight;
  padding-bottom: 4rem;
}

.disableContent {
  background: $grey;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .modal {
    border-radius: 0;
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
  }
}
