// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  padding-bottom: 8rem;
}

.image {
  height: 440px;
  object-fit: cover;
  width: 100%;
}

.pageIntro {
  display: flex;
  max-width: 1200px;
  margin: 3rem auto;
  padding: 0 2rem;
}

.heading {
  font-size: 4rem;
  margin: 0 6rem 0 0;
}

.paragraph {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}

.callToActionButton {
  border-bottom: 4px solid $skipli-red;
  border-radius: 0;
  font-size: 1.2rem;
  padding-bottom: 0.6rem;
  &:hover {
    padding: 0 1rem 0.6rem 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .image {
    height: 200px;
    margin-top: 66px;
  }

  .pageIntro {
    flex-direction: column;
  }

  .heading {
    font-size: 2rem;
    line-height: 2rem;
    margin-right: 0;
  }
}
