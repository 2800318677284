// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.page_container {
  background: url("https://skipli.s3.amazonaws.com/Skipli+Website/Error+Page/404-background.png");
  height: 100vh;
}

.skipli_logo { 
  cursor: pointer;
  padding: 30px 0 0 30px;
  & > svg {
    height: 1.6rem;
    width: auto;
  }
}

.content_container {
  color: #fff;
  margin: 30px auto;
  text-align: center;
  width: 700px;
}

.header {
  font-size: 48px;
  font-weight: 600;
  margin-top: 0;
}

.description {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.4;
}

.back_button {
  background: #fff;
  color: #000;
  margin-top: 40px;
  padding: 14px 30px;
}

.image {
  height: 200px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  .skipli_logo {
    padding-left: 0;
    text-align: center; 
  }

  .content_container {
    padding: 20px 20px 0 20px;
    width: auto;
  }

  .header {
    font-size: 36px;
  }

  .description {
    font-size: 24px;
    line-height: 30px;
  }

  .back_button {
    font-size: 1.1rem;
  }
}
