// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.background {
  background: rgb(240, 246, 250);
  padding: 100px 0 150px;
}

.title {
  color: $skipli-red;
  font-size: 94px;
  line-height: 100%;
  margin: 0 0 35px;
  text-align: center;
}

.description {
  color: #7A8297;
  font-size: 26px;
  line-height: 140%;
  margin: 0 auto 30px auto;
  max-width: 680px;
  text-align: center;
}

.features_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 22px;
  margin: 70px auto 0 auto;
  max-width: 840px;
}

.feature {
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 12.314px 36.9421px rgb(90 84 234 / 25%);
  border-radius: 100px;
  display: flex;
  padding: 8px 20px 8px 8px;
  width: auto;
}

.check_icon {
  align-items: center;
  background: linear-gradient(75.2deg, #FF5846 3.18%, $skipli-red 94.07%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  height: 60px;
  margin-right: 12px;
  width: 60px;
  & > svg {
    fill: #fff;
    height: 24px;
    width: 24px;
  }
}

.feature_name {
  font-size: 20px;
  line-height: 100%;
}

@media only screen and (max-width: 900px) {
  .background {
    padding: 0;
  }

  .title {
    font-size: 54px
  }

  .description {
    font-size: 20px;
  }

  .features_container {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
  }

  .feature {
    box-shadow: 0px 77.9359px 62.3488px rgb(0 0 0 / 5%), 0px 32.5598px 26.0478px rgb(0 0 0 / 4%), 0px 17.408px 13.9264px rgb(0 0 0 / 3%), 0px 9.75879px 7.80703px rgb(0 0 0 / 3%), 0px 5.18282px 4.14626px rgb(0 0 0 / 2%), 0px 2.15669px 1.72535px rgb(0 0 0 / 1%);
    margin: auto;
    width: 208px;
  }

  .check_icon {
    height: 46px;
    width: 46px;
    & > svg {
      height: 20px;
      width: 20px;
    }
  }

  .feature_name {
    font-size: 18px;
  }
}

@media only screen and (max-width: 520px) {
  .features_container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px 0px;
  }
}