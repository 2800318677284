// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.notAcceptingOrder {
  background: #f7fafc;
  border-top: 1px solid $border-color-dark;
  border-radius: inherit;
  padding: 1.4rem 1.8rem;
  display: flex;
}
.modal {
  border-radius: 0;
  bottom: 0;
  height: 100%;
  max-height: 100%;
  right: auto;
}
.infoContainer {
  display: flex;
  margin-bottom: 1.2rem;
}
.icon {
  height: auto;
  margin-right: 12px;
  width: 16px;
}
.special_hour_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0.6;
  &.isToday {
    opacity: 1;
    font-weight: bold;
  }
}
.special_hour_date {
  text-transform: capitalize;
  width: 105px;
}
.time_pickers_group {
  align-items: center;
  display: flex;
}
.icon {
  height: 1.2rem;
  margin-right: 1rem;
  width: 1.2rem;
  & > svg {
    fill: $dark-grey;
    height: inherit;
    width: inherit;
  }
}

.text {
  & > h3 {
    font-size: 1rem;
    margin: 0 0 0.4rem 0;
    text-transform: capitalize;
  }
}

.extraHours {
  margin-top: 0.3rem;
}

.seeTimeAvailable {
  border-bottom: 1px solid $info;
  color: $info;
  cursor: pointer;
  margin-top: 0.6rem;
  padding-bottom: 0.2rem;
  width: fit-content;
}

