// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.pageContainer {
  padding-top: 20px;
}

.sectionContainer {
  max-width: 1024px;
  margin: 100px auto;
  padding: 0 30px;
}

.textBlock {
  color: $text-color;
  line-height: 20px;
}

.privacyBtn {
  color: $info;
}
