// BUTI DINERS, INC. All right Reserved ©

.title {
  text-align: center;
  & > h2 {
    font-size: 2.2rem;
  }
  & > p {
    font-size: 1.2rem;
    line-height: 33px;
    max-width: 600px;
    margin: auto auto 50px auto;
  }
}

.feature_container {
  border: 1px solid #d5d8db;
  height: 34vw;
  &.active {
    opacity: 1;
  }
}

.feature_picture {
  background: #f1f4f6;
  height: 22vw;
  opacity: 0.8;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & > img {
    height: 22vw;
  }
}

.feature_info {
  padding: 25px 25px 0 25px;
  text-align: left;
  & > p {
    font-size: 1rem;
    line-height: 26px;
  }
}

.feature_label {
  font-size: 1.2rem;
  font-weight: bold;
}