// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.box {
  box-shadow: -4px 4px 8px 0 rgba(48, 50, 59, 0.05);
  max-width: 34rem;
  margin: auto;
  padding: 2rem;
}

.submitButton {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
}

.privacy_notice {
  color: #30323b;
  font-size: 0.8rem;
  max-width: 36rem;
  margin: 1.4rem auto 1rem auto;
  line-height: 1.2rem;
  text-align: center;
  & > a {
    color: #30323b;
    text-decoration: underline;
  }
}

.call_us {
  @extend .privacy_notice;
  margin-top: 0;
}

.phone_number {
  color: #000;
  border-bottom: 2px solid $skipli-red;
  font-weight: bold;
  padding-bottom: 0.2rem;
  text-decoration: none !important;
  &:hover {
    padding: 0 0.4rem 0.2rem 0.4rem;
  }
}

.inputField {
  margin-bottom: 1rem;
}

.checkBoxOption {
  margin-right: 0;
  width: 100%;
}

.learnMoreLink {
  color: $info;
  font-size: 0.86rem;
  & > svg {
    fill: $info;
    height: 0.8em;
    margin-bottom: -1.2px;
    width: 0.8rem;
  }
}
