// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.heading {
  align-items: center;
  display: flex;
  font-weight: bold;
  margin: 0 0 16px 0;
  text-transform: capitalize;
}

.requiredSign {
  background: $skipli-red;
  border-radius: 4px;
  color: #fff;
  font-size: 0.7rem;
  margin-left: 15px;
  padding: 4px 6px;
  text-transform: uppercase;
}

.optionalSign {
  color: $dark-grey;
  margin-left: 10px;
  text-transform: uppercase;
}

.optionsContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.option {
  margin: 0 20px 20px 0;
}
