// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$item-detail-image-height: 300px;

.container {
  border-bottom: 1px solid $border-color-dark;
  padding: 1rem 0;
}

.itemInCart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.itemDetailLazyLoadImg {
  width: 100vw;
  height: 80vw;
}

.itemDetailImg {
  border-radius: 0;
  height: 260px;
  max-height: 260px;
  object-fit: cover;
  width: 100%;
}

.itemCount {
  background: #000;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9rem;
  height: 2rem;
  width: 2rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;
  margin-bottom: 1rem;
  & > svg {
    fill: #fff;
    height: 0.7rem;
    margin-left: 2px;
    margin-top: 2px;
    transform: rotate(45deg);
    width: 0.7rem;
  }
}

// ------------------------- Item Info Column -------------------------

.itemInfoColumn {
  display: flex;
  align-items: flex-start;
  width: 80%;
  max-width: 80%;
}

.itemDetailsColumn {
  padding-right: 15px;
  text-align: left;
}

.itemName {
  line-height: 1.4rem;
}

.itemExtraInfo {
  color: #2d3436;
  margin-top: 0.6rem;
  word-wrap: break-word;
}

.customerInstruction {
  margin-top: 0.6rem;
}

.editItemOptions {
  display: flex;
  margin-top: 1rem;
  & > button {
    min-width: 40px;
    text-align: left;
  }
}

.ifSoldOut {
  color: #828282;
  margin: 0.6rem 0 0 0;
}

.editButton {
  color: $info;
  margin-right: 1rem;
}

.removeButton {
  color: $skipli-red;
}

// ------ Price Column ------

.priceCol {
  color: #828282;
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  width: 20%;
  max-width: 20%;
  justify-content: flex-end;
}