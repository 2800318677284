// BUTI DINERS, INC. All right Reserved ©

.order {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
  width: 100%;
}

.firstRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & > div {
    margin-right: 20px;
  }
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.moreInfo {
  margin-top: 15px;
  text-align: left;
}

.expandButton {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
}

.arrowIcon {
  margin-left: 10px;
  height: 20px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 20px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  &.collapse {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
