// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.headingContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.heading {
  font-size: 0.94rem;
  margin: 0;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.useCurrentLocationBtn {
  background: #2c3e50;
  border-radius: 100px;
  font-size: 0.7rem;
  margin-left: 14px;
  padding: 4px 10px;
  & > svg {
    fill: #fff;
    height: 16px;
    margin-right: 6px;
    width: 16px;
  }
}

.fieldContainer {
  margin-bottom: 12px;
}

.streetAddress1 {
  width: 100%;
}

.streetAddress2 {
  width: 100%;
}

.city {
  width: 58%;
}

.state {
  width: 36%;
}

.zip {
  width: 100%;
}

.notes {
  margin-top: 1rem;
  width: 100%;
}

.dropoff_options {
  margin-top: 1rem;
}
