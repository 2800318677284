// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "../style.module.scss";

.footerContainer {
  background: #fff;
  border-top: 1px solid $border-color-dark;
  padding: 1rem $paddingLeftRight;
  // Display
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // Position
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 50;
}

.cancelButton,
.submitButton {
  margin: 0 0 0 1rem;
  padding: 0.6rem 0;
  min-width: 6.8rem;
}

.cancelButton {
  font-weight: 400;
  min-width: 6.6rem;
}

.submitButton {
  font-weight: bold;
  min-width: 8.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
