// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$border-color: #e6e8eb;

.container {
  max-width: 80rem;
  margin: 2.4rem auto auto auto;
  padding-bottom: 6rem;
}

.pricingTabs {
  height: 3rem;
  width: 200px;
}

.saving {
  font-size: 0.8rem;
}

.pricingTab {
  height: 80%;
}

.tableHeader {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.featureColumn {
  width: 30%;
}

.planColumn {
  width: 23.33%;
}

.planName {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.popularBadge {
  border: 1px solid #e0e5fd;
  border-radius: $global-border-radius;
  color: $primary;
  font-size: 0.9rem;
  margin-left: 0.6rem;
  padding: 0.2rem 0.4rem;
}

.payoutSchedule {
  font-size: 0.9rem;
  margin: 0.4rem 0 1.4rem 0;
}

.startTrialBtn {
  font-size: 1rem;
  margin: 0 0 2rem 0;
  padding: 0.8rem 1.2rem;
}

.featureGroup {
  margin-bottom: 2rem;
}

.tableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:nth-child(odd) {
    background: $grey;
  }
}

.cell {
  display: flex;
  font-size: 0.85rem;
  line-height: 1.2rem;
  padding: 12px 20px;
}

.checkIcon {
  fill: $primary;
  height: 1rem;
  width: 1rem;
}

.closeIcon {
  fill: #bdc3c7;
  height: 0.8rem;
  width: 0.8rem;
}

.rate {
  font-size: 0.9rem;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .tableHeader {
    top: 55px;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    margin-top: 2rem;
    padding: 0 20px 6rem 20px;
  }

  .tableHeader {
    align-items: flex-end;
  }

  .pricingTabs {
    height: 40px;
    max-width: 200px;
    position: absolute;
    top: 5px;
    left: 0;
    margin: auto;
    right: 0;
  }

  .pricingTab {
    font-size: 0.8rem;
  }

  .planName {
    align-items: flex-start;
    flex-direction: column-reverse;
    font-size: 14px;
    margin-top: 4rem;
    padding: 0 10px;
  }

  .popularBadge {
    border: none;
    font-size: 0.8rem;
    line-height: 16px;
    margin-left: 0;
    margin-bottom: 0.4rem;
    padding: 0;
  }

  .payoutSchedule {
    font-size: 12px;
    padding: 0 10px;
  }

  .startTrialBtn {
    font-size: 12px;
    margin: 0 10px 20px 10px;
    padding: 0;
    background: transparent;
    color: $primary;
    text-align: left;
    &:hover {
      box-shadow: none;
    }
  }

  .featureGroup > h3 {
    font-size: 0.9rem;
  }

  .cell {
    padding: 5px;
    font-size: 0.65rem;
  }

  .checkIcon {
    height: 0.8rem;
    margin-left: 15px;
    width: 0.8rem;
  }

  .closeIcon {
    height: 0.6rem;
    margin-left: 15px;
    width: 0.6rem;
  }
}
