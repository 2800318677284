// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~styles/mainWebsite.module";

.pageContainer {
  padding-top: 66px;
}

.sectionContainer {
  @extend .sectionContainer;
}

.callButton {
  @extend .letsChatBoxSecondaryBtn;
}

.getFreeDemoBtn {
  @extend .letsChatBoxMainBtn;
}

.heroImage {
  border-radius: $global-border-radius;
}
