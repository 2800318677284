// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.fee_summary_container {
  background: $grey;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 30px;
  padding: 20px;
}

.label_see_detail {
  margin-bottom: 10px;
  color: $primary;
  cursor: pointer;
}
.modal {
  max-width: 400px;
}
.label {
  display: flex;
  align-items: center;
  margin: 0;
}

.order_details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  & > p {
    margin: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.infoIcon {
  cursor: pointer;
  fill: #000000;
  height: 14px;
  margin-left: 8px;
  width: 14px;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #000;
  padding-top: 10px;
  margin-top: 10px;
  & > p {
    margin: 0;
  }
}

.modal_content {
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  text-align: justify;
}