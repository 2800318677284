// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: #fafafb;
  padding: 60px 0;
}

.title {
  color: #5f7494;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto 60px auto;
  line-height: 3.6rem;
}

// -----------------------------------------------------------------
// Desktop Carousel

.slides_container {
  margin-left: -48rem;
}

.slide {
  opacity: 1;
}

.testimony_container {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 100px 249px rgb(0 0 0 / 2%), 0 41.7776px 104.026px rgb(0 0 0 / 1%), 0 22.3363px 55.6174px rgb(0 0 0 / 1%), 0 12.5216px 31.1787px rgb(0 0 0 / 1%), 0 6.6501px 16.5588px rgb(0 0 0 / 1%), 0 2.76726px 6.89047px rgb(0 0 0 / 1%);
  display: flex;
  height: 450px;
  width: 720px;
}

.profile_pic_column {
  border-radius: 20px 10px 22% 20px;
  clip-path: polygon(0 100%, 85% 99.5%, 100% 0, 0 0);
  margin: -10px 0 -15px;
  min-width: 288px;
  overflow: hidden;
  position: relative;
}

.profile_pic_container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  & > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.text_column {
  padding: 40px 40px 40px 50px;
}

.logo {
  max-height: 50px;
  margin-bottom: 40px;
  width: auto;
}

.testimony, .owner_name {
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.testimony {
  margin: 0 0 30px 0;
}

.owner_name {
  color: $skipli-red;
  font-weight: 600;
  margin: 0;
}

.owner_of {
  color: #5f7494;
  font-size: 15px;
  font-weight: 600;
}

// -----------------------------------------------------------------
// Mobile Carousel

.mobile_carousel {
  display: none;
}

.mobile_slides_container {
  margin-left: -120vw;
  margin-bottom: 20px;
}

.mobile_testimony_container {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 100px 249px rgb(0 0 0 / 2%), 0 41.7776px 104.026px rgb(0 0 0 / 1%), 0 22.3363px 55.6174px rgb(0 0 0 / 1%), 0 12.5216px 31.1787px rgb(0 0 0 / 1%), 0 6.6501px 16.5588px rgb(0 0 0 / 1%), 0 2.76726px 6.89047px rgb(0 0 0 / 1%);
  display: flex;
  flex-direction: column;
  width: 92vw;
}

.mobile_profile_pic_container {
  border-radius: 20px 20px 0 0;
  height: 200px;
  width: 100%;
  & > img {
    border-radius: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;
  }
}

.mobile_text_column {
  padding: 20px;
}

.mobile_logo {
  max-height: 40px;
}

.mobile_testimony {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.mobile_owner_name {
  color: $skipli-red;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 2px;
}

.mobile_owner_of {
  color: #5f7494;
  font-size: 15px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .container {
    padding: 60px 2rem;
  }

  .title {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .desktop_carousel {
    display: none;
  }

  .mobile_carousel {
    display: block;
  }
}