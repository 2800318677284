// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.deliveryPartners {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  grid-gap: 8px 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.partnerUrl {
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  margin: 0 1rem 1rem 1rem;
  order: 2;
  text-decoration: none;
  width: 6rem;
  &.skipli {
    order: 1 !important;
  }
  &.unknownPartner {
    order: 3;
  }
}

.partnerLogo {
  height: auto;
  width: inherit;
  & > svg {
    height: inherit;
    width: inherit;
  }
}

.unknownPartnerName {
  color: $grey-medium;
}

.genericLogo {
  height: 1.4rem;
  margin-right: 0.4rem;
  width: 1.4rem;
  & > svg {
    fill: $grey-medium;
    height: inherit;
    width: inherit;
  }
}

@media only screen and (max-width: 700px) {
  .deliveryPartners {
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 20px 24px;
  }

  .partnerUrl {
    margin: 0;
  }
}
