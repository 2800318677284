// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$image-height: 46rem;

.container {
  max-height: $image-height;
  position: relative;
}

.heroImage {
  max-height: inherit;
  object-fit: cover;
  width: 100vw;
}

.filter {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.watermark {
  height: 180px;
  width: 180px;
  position: absolute;
  &.left {
    top: 2.5rem;
    left: 5vw;
  }
  &.right {
    top: 2.5rem;
    right: 5vw;
  }
}

.textContainer {
  max-width: 600px;
  position: absolute;
  top: 12vw;
  &.center {
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0%);
  }
  &.left {
    left: 10vw;
  }
  &.right {
    right: 8vw;
  }
}

.heading {
  color: #fff;
  font-size: 2.6rem;
}

.message {
  color: #fff;
  font-size: 1.6rem;
  line-height: 36px;
}

.buttons {
  display: flex;
  &.center {
    justify-content: center;
  }
}

.button {
  font-size: 1.2rem;
  margin: 40px 0 0 0;
  padding: 14px 40px;
}

.orderButton {
  @extend .button;
  margin-right: 20px;
}

.seeMenuButton {
  @extend .button;
  background: #fff;
  color: #000;
}

@media only screen and (max-width: 700px) {
  .heroImage,
  .filter {
    height: 80vw;
  }

  .watermark {
    display: none;
  }

  .textContainer {
    left: 2rem !important;
    top: 2rem !important;
    right: 2rem !important;
    transform: none !important;
    max-width: 500px;
    margin-right: 15px;
  }

  .heading {
    font-size: 1.6rem;
    margin-top: 0;
  }

  .message {
    font-size: 0.9rem;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
  }

  .button {
    font-size: 0.9rem;
    margin-top: 0.8rem;
    padding: 0.8rem 1rem;
  }
}

@media only screen and (max-width: 360px) {
  .heroImage,
  .filter {
    height: 120vw;
  }
}
