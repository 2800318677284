// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

$modal-width: 28rem;
$shadow: 0 3px 5px -1px rgba(245, 129, 129, 0.2),
  0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

// ------------- Modal Styling -------------

.modal {
  border-radius: 0;
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
}

.contentContainer {
  height: 100%;
  padding: 0;
}

.desktop_close_modal_icon {
  background: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  height: 36px;
  width: 36px;
  position: absolute;
  right: 20px;
  top: 20px;
  & > svg {
    height: 13px;
    width: 13px;
  }
}

.imageDisclaimer {
  color: #8f95a3;
  font-size: 0.6rem;
  margin-top: 2px;
  padding: 0.2rem 25px;
}

@media only screen and (max-width: 500px) {
  .modalClassname {
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    margin-top: 0;
  }
}

@media only screen and (max-height: 700px) {
  .modalClassname {
    height: 100vh;
    margin-top: 0;
  }
}
