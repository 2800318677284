// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.extraFeesMsg {
  color: $dark-grey;
  font-size: 0.5rem;
}

.message {
  color: $dark-grey;
  line-height: 22px;
  text-align: center;
  margin-bottom: 100px;
}
