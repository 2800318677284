// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "~components/WebsiteBuilder/style.module.scss";

.container {
  bottom: 40px;
  padding-top: 0;
  position: relative;
}

.pill {
  background-color: white;
  border-radius: 100px;
  box-shadow: $shadow-light;
  cursor: pointer;
  padding: 2rem 1.6rem;
  //Display
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 28rem;
}

.watermark {
  height: 70px;
  margin-right: 1.4rem;
  width: 70px;
}

.heading {
  font-size: 1rem;
  margin: 0;
}

.generalText {
  font-size: 0.9rem;
  margin: 0.6rem 0;
}

.seeBenefits {
  font-size: 0.9rem;
  text-decoration: underline;
  text-underline-position: under;
}

@media only screen and (max-width: 700px) {
  .container {
    bottom: 0;
  }

  .pill {
    border-radius: 0;;
    box-shadow: none;
    padding: 1.2rem 2rem;
    width: inherit;
  }

  .watermark {
    height: 60px;
    width: 60px;
  }

  .heading {
    font-size: 1rem;
  }

  .generalText {
    font-size: 0.86rem;
    margin: 0.3rem 0;
    line-height: 1.2rem;
  }

  .seeBenefits {
    font-size: 0.86rem;
    text-decoration: underline;
    text-underline-position: under;
  }
}
