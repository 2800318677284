// BUTI DINERS, INC. All right Reserved ©

$shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);

.timePicker {
  border-radius: 10px;
  padding: 0px 15px;
  height: 40px;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  & > div {
    border: none !important;
  }
}
