// BUTI DINERS, INC. All right Reserved ©

.container {
  max-width: 1200px;
  margin: 0 auto 100px auto;
  padding: 0 30px;
}

.callToAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h2 {
    font-size: 2rem;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    margin-bottom: 50px;
  }

  .callToAction {
    & > h2 {
      text-align: center;
    }
  }
}
