// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  background: $skipli-red;
  margin: 2rem 0 4rem 0;
}

.content {
  display: flex;
  max-width: 1200px;
  padding: 4rem 2rem;
  margin: auto;
}

.heading {
  color: #fff;
  font-size: 5.6rem;
  margin: 0 6rem 0 0;
}

.paragraph {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
}

.seeBenefitsButton {
  background: none;
  border-bottom: 4px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 1.2rem;
  margin: 2rem 0 0 0;
  padding: 0 0 0.6rem 0;
  &:hover {
    box-shadow: none;
    padding: 0 1rem 0.6rem 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .content {
    flex-direction: column;
  }

  .heading {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .heading {
    font-size: 4rem;
  }
}
