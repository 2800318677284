// BUTI Corp All right Reserved ©

.comingSoonButton {
  background: #f0f0f0;
  font-weight: bold;
  height: 40px;
  padding: 0 15px;
  pointer-events: none;
  text-transform: uppercase;
}
