// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.container {
  max-width: 600px;
}

.heading {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 40px 0 0 0;
}

.paragraph {
  color: #6b7c93;
  font-size: 1rem;
  line-height: 25px;
  margin-top: 10px;
}

.form {
  padding-top: 20px;
}

.field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.inputLabel,
.checkboxGroupHeading,
.checkBox {
  font-size: 0.9rem;
}

.inputLabel {
  font-size: 0.9rem;
  width: 25%;
  text-transform: capitalize;
}

.inputField {
  width: 300px;
}

.submitButton {
  font-size: 0.9rem;
  height: 40px;
  margin: 30px 0px auto 0;
  text-transform: uppercase;
  width: 240px;
}

@media only screen and (max-width: 1300px) {
  .inputLabel {
    margin-bottom: 8px;
    width: 100%;
  }
}
