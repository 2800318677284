// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";
@import "../style.module.scss";

$column_1_width: 500px;

.container {
  display: flex;
  height: 100%;
  // &.extraPaddingTop {
  //   padding-top: 1rem;
  // }
}

// ------------------------- Column 1 -------------------------

.column_1 {
  background: rgb(248, 248, 248);
  width: $column_1_width;
}

.imageDisclaimer {
  color: #8f95a3;
  font-size: 10px;
  padding: 10px 20px 0 20px;
  margin-top: 0;
}

.item_info_container {
  padding: 30px 40px;
}

// ------------------------- Column 2 -------------------------

.column_2 {
  overflow: auto;
  width: calc(100% - $column_1_width);
}

.column_2_content {
  margin-left: auto;
  margin-right: auto;
  max-width: 560px;
  padding: 60px 30px 80px 30px;
}

.flexSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionContainer {
  margin-bottom: 2rem;
}

.sectionNameContainer {
  @extend .flexSpaceBetween;
  align-items: flex-start;
  border-bottom: 1px solid $border-color-dark;
  margin: 0 0 15px;
  font-size: 0.8rem;
  padding-bottom: 10px;
}

.sectionName {
  font-size: 16px;
  margin: 0;
}

.sectionText {
  text-transform: capitalize;
  font-size: 1rem;
  margin-bottom: 5px;
}

.arrowIcon {
  height: 20px;
  transform: rotate(180deg);
  width: inherit;
  margin-left: -10px;
}

.specialInstrDisclaimer {
  line-height: 140%;
  font-weight: 400;
}


// ------------- Size Selector -------------

.subText {
  color: $dark-grey;
}

// ------------- Dietary Restrictions -------------

.dietaryRestrictionsContainer {
  @extend .flexSpaceBetween;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 5px 0;
}

.dietaryRestriction {
  width: 30%;
  margin: 0 10px 1.2vh 0;
}

// ------------- Footer -------------

.footerContainer {
  margin: auto 0 0 auto;
  width: calc(100% - $column_1_width);
  // position
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 50;
}

.sbqcContainer {
  box-shadow: 0 0 6px 0 rgba(0,0,0,.05);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 40px;
}

@media only screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .column_1, .column_2 {
    width: 100%;
  }

  .column_2 {
    overflow: unset;
  }

  .item_info_container {
    padding: 30px 20px;
  }

  .column_2_content {
    padding: 30px 20px 120px 20px;
  }

  .sbqcContainer {
    padding: 12px 20px;
    justify-content: center;
  }

  .footerContainer {
    width: 100%;
  }
}
