// BUTI Corp All right Reserved ©
// Son That Ton
// sonton@gatech.edu

.optionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.textContainer {
  padding-right: 20px;
  width: 85%;
}

.switchContainer {
  width: 15%;
}

.label {
  font-size: 1rem;
  margin-bottom: 10px;
}

.description {
  font-size: 0.9rem;
  line-height: 22px;
}

.switchIcon {
  width: 100px;
}
