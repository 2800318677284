// BUTI Corp All right Reserved ©

.labelOptionContainer {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-size: 0.8rem;
  align-items: center;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  & > p {
    margin: 0;
  }
}

.labelOptionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
