// BUTI DINERS, INC. All right Reserved ©

@import "~styles/variables";

.tab {
  text-transform: uppercase;
}

.tabLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    height: 1rem;
    margin-right: 0.6rem;
    width: 1rem;
  }
}

.selected > .tabLabel > svg {
  fill: $skipli-red;
}
